const DEALERSHIP_FORM_FIELDS = {
  //generalInfo
  DETAIL_ID: "DetailId",
  DEALERSHIP_NAME: "DealershipName",
  PROFIT_CENTER: "ProfitCenter",
  COMPANY_NUMBER: "CompanyNumber",
  SALE_TAX_NUMBER: "SaleTaxNumber",
  FEDRAL_ID_NUMBER: "FedralIdNumber",
  STATE_ID_NUMBER: "StateIdNumber",
  //Address
  ADDRESS_ID: "AddressId",
  ADDRESS_TYPE: "AddressType",
  ADDRESS_DESCRIPTION: "AddressDescription",
  REGION: "Region",
  STREETADDRESS1: "StreetAddress1",
  STREETADDRESS2: "StreetAddress2",
  CITY: "City",
  STATE: "State",
  POSTALCODE: "PostalCode",
  COUNTY: "County",
  COUNTRY: "Country",
  //phones
  PHONE_ID: "PhoneId",
  PHONE_TYPE: "PhoneType",
  PHONE_NUMBER: "PhoneNumber",
  PHONE_DESCRIPTION: "PhoneDescription",
  //Emails
  EMAIL_ID: "EmailId",
  EMAIL_TYPE: "EmailType",
  PRIMARY_EMAIL: "PrimaryEmail",
  EMAIL_ADDRESS: "EmailAddress",
  EMAIL_DESCRIPTION: "EmailDescription",
  //Web
  WEB_ID: "WebId",
  WEB_TYPE: "WebType",
  WEB_PRIMARY: "WebPrimary",
  WEB_DESCRIPTION: "WebDescription",
  WEB_URL: "WebURL",
  DISPLAY_NAME: "DisplayName",

  //Hours
  HOURS_ID: "HourId",
  SHIFT_DAYS: "ShiftDays",
  HOURS_START_TIME: "HoursStartTime",
  HOURS_END_TIME: "HoursEndTime",
  BUSINESS_HOURS_DISPLAY: "BusinessHoursDisplay",
  BUSINESS_HOURS_DESCRIPTION: "BusinessHoursDescription",

  //Holidays
  HOLIDAY_ID: "HolidayId",
  HOURS_SELECT_DATE: "HoursSelectDate",
  HOLIDAY_HOURS_START_TIME: "HoursStartTime",
  HOLIDAY_HOURS_END_TIME: "HoursEndTime",
  HOLIDAY_HOURS_DISPLAY: "HolidayHoursDisplay",
  HOLIDAY_HOURS_DESCRIPTION: "HolidayHoursDescription",
  REPEATS_YEARLY: "RepeatsYearly",
  ALL_DAY: "AllDay",

  //API
  API_DB_ID: "ApiDbId", // INFO: As ApiId is already there so have to store id in edit case in ApiDbId
  API_ID: "ApiId",
  TOKEN_EXP: "TokenExp",
  NEVER_EXP: "NeverExp",
  API_DESCRIPTION: "ApiDescription",
  STATUS: "Status",
  ACTIVE: "Active",
  INACTIVE: "InActive",
  API_KEY: "ApiKey",
  VALUE: "Value",

  //Settings
  SETTING_API_KEY: "SettingApiKey",
  SETTING_DISPLAY_NAME: "SettingDisplayName",
  SELECT_TYPE: "SelectType",
  SETTING_DESCRIPTION: "SettingDescription",
  MAKE_FIELD_REQUIRED: "MakeFieldRequired",
  ALLOW_MULTIPLE_VALUES: "MakeFieldRequired",
  ENABLE_SMTP: "EnableSMTP",
  ENABLE_TRACKING: "EnableTracking",
  //Lots
  LOT_ID: "LotId",
  LOT_NAME: "LotName",
  LOT_TYPE: "LotType",
  LOT_LOCATION_ID: "LotLocationId",
  LOT_LOCATION_NAME: "LotLocationName",
  LOT_LOCATION_COLOR: "LotLocationColor",
  LOT_IS_ACTIVE: "LotIsActive",
  DEALERSHIP_LOTS: "DealershipLots",
  DEALERSHIP_LOT_LOCATIONS: "DealershipLotLocations",
  LOT_LOCATION_NAME_COUNT: "LotLocationNameCount", // Info: This field is being used to access the count of nested lot name and color

  //generalInfo subgroup
  GROUP_NAME: "GroupName",
  PARENT_GROUP: "ParentGroup",
} as const;

export default DEALERSHIP_FORM_FIELDS;
