import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

// The store is configured with the state and the corresponding reducers.
const store = configureStore({
  reducer: rootReducer,
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
