import { List } from "@mui/material";
import { Link } from "react-router-dom";
import useSubmenu from "./useSubmenu";
import { sidebar__link } from "appConstants/styles";
const Submenu = ({ subNavItems }) => {
  const checkIsActiveItem = useSubmenu();
  return (
    <List sx={sidebar__link.subNavLinkStyle}>
      {subNavItems?.map((subItem, index) =>
        subItem.label ? (
          <Link
            to={subItem.link}
            className={checkIsActiveItem(subItem) && "active"}
            key={index}
          >
            {subItem.label}
          </Link>
        ) : (
          ""
        )
      )}
    </List>
  );
};

export default Submenu;
