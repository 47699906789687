import { keyframes } from "@emotion/react";
import placeholder from "./../images/placeholder.svg";
import theme from "utils/theme";
import { SxProps } from "@mui/material";

// px to rem function [Start]
export const pxToRem = (...pxValues) =>
  /* Example usage: <Stack sx={{padding: pxToRem(50, 0, 0)}} />
// note in this example css will be  "padding: 50px 0 0;"
// top: 50px, (left & right): 0, bottom: 0
*/
  pxValues.map((px) => `${px / 16}rem`).join(" ");
// px to rem function [END]

// convert custom palette color to rgba [Start]
export const hexToRGBA = (hex, alpha) => {
  // Example usage 1: hexToRGBA("#0066cc", 0.2));
  // Example usage 2: with "themeColorHex" function
  // const hexColor = themeColorHex('appModules[200]');

  const hexWithoutHash = hex.replace(/^#/, ""); // Remove the hash if present
  const bigint = parseInt(hexWithoutHash, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
// convert custom palette color to rgba [END]

// get pre process hex value from theme [Start]
export const themeColorHex = (propertyString) => {
  //Example  usage : themeColorHex('appModules[200]')
  const [property, index] = propertyString.split("[");
  if (!property || !index) {
    return null; // Invalid input format
  }

  const key = index.slice(0, -1); // Remove the trailing ']'
  const color = theme.palette[property]?.[key];

  return color;
};
// get pre process hex value from theme [END]

//common
export const themeStyle = theme;
export const gutterSpace = pxToRem(17);
// page bottom spacing
export const pageBottomSpacing = pxToRem(120);
// form right padding
export const formRightPad = gutterSpace;
//sidebar drawer width
export const drawerWidth = { open: 283, default: 67 };

// Main Sidebar [Start]
export const sidebar__link = {
  "*": {
    transition: "all 0.3s ease-in-out",
  },
  // dealership
  "&.section-dealership": {
    ".svg-path": {
      fill: (theme) => theme.palette.appModules["100"],
    },

    //active / hover
    "&:hover , &.isActive": {
      ".MuiStack-root": {
        backgroundColor: "appModules.100",

        ".svg-path": {
          fill: (theme) => theme.palette.grey["A400"],
        },
      },
    },
  },
  // inventory
  "&.section-inventory": {
    ".svg-path": {
      fill: (theme) => theme.palette.appModules["200"],
    },

    //active / hover
    "&:hover , &.isActive": {
      ".MuiStack-root": {
        backgroundColor: "appModules.200",

        ".svg-path": {
          fill: (theme) => theme.palette.grey["A400"],
        },
      },
    },
  },
  // finance
  "&.section-finance": {
    ".svg-path": {
      fill: (theme) => theme.palette.appModules["300"],
    },

    //active / hover
    "&:hover , &.isActive": {
      ".MuiStack-root": {
        backgroundColor: "appModules.300",

        ".svg-path": {
          fill: (theme) => theme.palette.grey["A400"],
        },
      },
    },
  },
  // personnel
  "&.section-personnel": {
    ".svg-path": {
      fill: (theme) => theme.palette.appModules["400"],
    },

    //active / hover
    "&:hover , &.isActive": {
      ".MuiStack-root": {
        backgroundColor: "appModules.400",

        ".svg-path": {
          fill: (theme) => theme.palette.grey["A400"],
        },
      },
    },
  },
  //reports
  "&.section-reports ": {
    ".svg-path": {
      fill: (theme) => theme.palette.appModules["500"],
    },

    //active / hover
    "&:hover , &.isActive": {
      ".MuiStack-root": {
        backgroundColor: "appModules.500",

        ".svg-path": {
          fill: (theme) => theme.palette.grey["A400"],
        },
      },
    },
  },
  // alert
  "&.section-alerts": {
    ".svg-path": {
      fill: (theme) => theme.palette.appModules["600"],
    },

    //active / hover
    "&:hover , &.isActive": {
      ".MuiStack-root": {
        backgroundColor: "appModules.600",

        ".svg-path": {
          fill: (theme) => theme.palette.grey["A400"],
        },
      },
    },
  },
  //clock
  "&.section-clock": {
    ".svg-path": {
      fill: (theme) => theme.palette.appModules["700"],
    },

    //active / hover
    "&:hover , &.isActive": {
      ".MuiStack-root": {
        backgroundColor: "appModules.700",

        ".svg-path": {
          fill: (theme) => theme.palette.grey["A400"],
        },
      },
    },
  },

  subNavLinkStyle: {
    margin: `${pxToRem(58)} 0 ${pxToRem(20)} ${gutterSpace}`,
    a: {
      color: "grey.100",
      padding: pxToRem(21),
      fontSize: pxToRem(13),
      fontWeight: "normal",
      display: "block",
      letterSpacing: pxToRem(0.25),
      marginBottom: pxToRem(10),
      textDecoration: "none",

      //hover / active
      "&:hover": {
        color: "grey.50",
      },
      "&.active": {
        backgroundColor: "greyVariantAlt.100",
      },
    },
  },
};
// Main Sidebar [END]

// OverFlow Grid fix
export const gridOverflowFix = {
  width: `calc(100vw - ${pxToRem(682)})`,
};

// Right Sidebar [Start]
export const rightSideBarStyle = {
  width: "100%",
  maxWidth: pxToRem(359),
}; // Right Sidebar [END]

// page Style
export const pageStyle = {
  padding: `0 ${gutterSpace}`,
  position: "relative",
  maxHeight: `calc(100vh - 5vh)`,
  overflow: "hidden",
};

// page heading
export const pageHeading = {
  "&.MuiStack-root": {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    borderBottomWidth: pxToRem(1),
    borderBottomStyle: "solid",
    borderBottomColor: (theme) => theme.palette.greyVariant[200],
    marginBottom: pxToRem(12),
  },
  //headingStyle
  headingStyle: {
    color: (theme) => theme.palette.greyVariant[100],
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: pxToRem(24),
    letterSpacing: pxToRem(0.15),
    paddingTop: pxToRem(14),
    paddingBottom: pxToRem(14),
  },
};

// card style
export const cardStyle = {
  backgroundColor: (theme) => theme.palette.greyVariantAlt[600],
  padding: pxToRem(10),
  borderRadius: pxToRem(4),
};

// Top indicator bar
export const topIndicatorBar = {
  width: "100%",
  height: pxToRem(9),
  spaceBottom: pxToRem(41),
};

// search
export const searchBar = {
  width: "100%",
  alignItems: "center",

  ".MuiInputBase-root": {
    margin: pxToRem(0, 14),
    flex: 1,
    width: "100%",
    border: `${pxToRem(1)} organge solid`,

    ".MuiInputBase-input": {
      ariaLabel: "search",
      padding: 0,
    },
  },

  showBorderBottomStyle: {
    borderBottomWidth: pxToRem(2),
    borderBottomStyle: "solid",
    borderBottomColor: (theme) => theme.palette.grey["A100"],
    borderRadius: 0,
  },
};

// scrollable
export const scrollable = {
  maxHeight: `calc( 100vh - (${topIndicatorBar.height} + ${topIndicatorBar.spaceBottom}) )`,
  overflow: "auto",
};

//link Button
export const linkButtonGrey50 = {
  color: "grey.50",
  fontSize: pxToRem(12),
  fontWeight: "500",
  letterSpacing: pxToRem(0.249),
  padding: pxToRem(14, 13),

  //icon
  ".MuiButton-startIcon": {
    "&>*:nth-of-type(1)": {
      fontSize: pxToRem(14),
    },
  },

  //hover / focuse / active
  "&:hover, &:focuse, &.active": {
    backgroundColor: "grey.900",
  },
};

export const linkButtonBGGreyA400: SxProps = {
  ...linkButtonGrey50,
  fontWeight: 400,
  backgroundColor: `${themeColorHex("grey[A400]")} !important`,
};

//link Button
export const linkButtonGrey50Font14 = {
  ...linkButtonGrey50,
  fontSize: pxToRem(14),
  padding: pxToRem(12, 16),
  height: pxToRem(43),
  "svg.MuiSvgIcon-root": {
    marginBottom: pxToRem(3),
    fontSize: `${pxToRem(16)} !important`,
  },
  //hover / focus / active
  "&:hover, &:focus, &.active": {
    backgroundColor: "grey.A100",
  },
};

export const slimGreyBtn = {
  ...linkButtonGrey50,

  color: "greyVariantAlt.800",
  fontSize: pxToRem(14),
  lineHeight: pxToRem(20),
  letterSpacing: pxToRem(0.1),
  padding: pxToRem(6, 16),

  ".MuiButton-startIcon": {
    color: "grey.50",
  },
};

//contained Button
export const containedButtonBlue = {
  "&.MuiButtonBase-root": {
    fontSize: pxToRem(14),
    fontWeight: "500",
    letterSpacing: pxToRem(0.249),
    padding: pxToRem(10, 24),
    width: "100%",
    boxShadow: "none",
    height: pxToRem(43),

    //icon
    ".MuiButton-startIcon": {
      marginRight: pxToRem(9),

      "&>*:nth-of-type(1)": {
        fontSize: pxToRem(14),
      },
    },

    primary: {
      //contanied
      "&:not(.Mui-disabled).MuiButton-contained": {
        backgroundColor: "secondary.main",
      },

      //hover / focuse / active
      "&:hover, &:focuse, &.active": {
        backgroundColor: "secondary.300",
      },
    },
  },
};

// Filter Wrapper [Start]
export const filterButton = {
  backgroundColor: "grey.A400",
  color: "greyVariantAlt.700",
  fontSize: pxToRem(14),
  fontWeight: 600,
  lineHeight: pxToRem(20),
  letterSpacing: pxToRem(0.1),
  padding: pxToRem(6, 16),
  borderRadius: pxToRem(4),
  border: `${pxToRem(1)} ${themeColorHex("greyVariantAlt[900]")} solid`,
  boxShadow: "unset",

  //icon
  ".MuiButton-startIcon": {
    color: "secondary.400",
    marginRight: pxToRem(8),
  },

  "&:hover": {
    backgroundColor: "greyVariantAlt.400",
    borderColor: "greyVariantAlt.400",
    boxShadow: `0 ${pxToRem(1, 2)} 0 ${hexToRGBA(
      themeColorHex("grey[50]"),
      0.3
    )}, 0 ${pxToRem(1, 3, 1)} ${hexToRGBA(themeColorHex("grey[50]"), 0.15)}`,
  },

  "&:focus": {
    backgroundColor: "greyVariantAlt.400",
    borderColor: "greyVariantAlt.400",
    boxShadow: `0 ${pxToRem(1, 2)} 0 ${hexToRGBA(
      themeColorHex("grey[50]"),
      0.3
    )}, 0 ${pxToRem(1, 3, 1)} ${hexToRGBA(themeColorHex("grey[50]"), 0.15)}`,
  },

  "&.active": {
    backgroundColor: "greyVariantAlt.400",
    borderColor: "greyVariantAlt.400",
    boxShadow: `0 ${pxToRem(1, 2)} 0 ${hexToRGBA(
      themeColorHex("grey[50]"),
      0.3
    )}, 0 ${pxToRem(1, 3, 1)} ${hexToRGBA(themeColorHex("grey[50]"), 0.15)}`,
  },
};

export const filterButtonSecondary = {
  ...filterButton,
  color: "greyVariantAlt.800",

  //icon
  ".MuiButton-startIcon": {
    color: "grey.50",
  },
};

export const colorDropdown = {
  backgroundColor: "secondary.A100",
  color: "tertiary.main",
  borderRadius: pxToRem(4),
  padding: pxToRem(6, 8),

  ".MuiIcon-root": {
    transform: `translateY(${pxToRem(-2)})`,
    marginRight: pxToRem(8),
  },

  ".MuiSelect-select": {
    color: "tertiary.main",
    fontWeight: 600,
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.1),
    padding: 0,
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};
// Filter Wrapper [END]

// DataGrid Wrapper [Start]
export const dataGridWrapper = {
  width: "100%",

  //datagrid [Start]
  ".MuiDataGrid-root": {
    border: "none",

    //header
    ".MuiDataGrid-columnHeaders": {
      backgroundColor: "grey.A400",
      borderRadius: pxToRem(4),
      border: "none",
      marginBottom: pxToRem(3),
      ".action-header .MuiDataGrid-columnHeaderTitleContainer": {
        justifyContent: "center",
      },
      ".MuiDataGrid-iconSeparator": {
        visibility: "visible",
      },
      ".MuiDataGrid-columnHeaderCheckbox": {
        "svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator": {
          display: "none",
        },
      },

      ".MuiDataGrid-columnHeader": {
        "&:last-of-type": {
          ".MuiDataGrid-columnSeparator--sideRight": {
            display: "none",
          },
          ".MuiSvgIcon-root": {
            marginRight: pxToRem(10),
          },
        },

        "&:not(:hover) .MuiDataGrid-menuIcon": {
          marginRight: pxToRem(20),
        },

        ".MuiDataGrid-menuIconButton": {
          visibility: "visible",
        },
      },
    },

    //datagrid body Holder
    ".MuiDataGrid-virtualScroller": {
      backgroundColor: "grey.A400",

      height: `calc(100vh  - ${pxToRem(233)})`,
      // maxHeight: `calc(100vh  - ${pxToRem(233)})`,
      overflow: "auto",

      ".MuiDataGrid-virtualScrollerRenderZone": {
        backgroundColor: "#ffffff",
        paddingBottom: pxToRem(60),

        ".MuiDataGrid-row": {
          maxHeight: "unset !important",

          ".MuiDataGrid-cell": {
            padding: pxToRem(12),
          },

          ".MuiCheckbox-root:not(.Mui-checked):not(:hover)": {
            ".MuiSvgIcon-root": {
              path: {
                fill: (theme) => theme.palette.grey[800],
              },
            },
          },
          "&.super-app-row-highlighted": {
            backgroundColor: "#ccc",
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "flex-start",
      ".MuiToolbar-root": {
        paddingX: "0",
      },
      "& .MuiTablePagination-actions": {
        display: "none",
      },
    },
  }, //datagrid [END]
  hideHeader: {
    "& .MuiDataGrid-columnHeaders": {
      display: "none", // INFO: Hides the header row
    },
    "& .MuiDataGrid-virtualScroller": {
      marginTop: "0px !important", // INFO: Adjusts for the missing header height
    },
  },
  cellStyleBaseOnColIndex: (index = "1") => ({
    paddingLeft: {
      // INFO: To add padding to left cell in case no checkbox selection in table
      [`div[aria-colindex="${index}"]`]: {
        pl: `${pxToRem(25)} !important`,
      },
    },
  }),
  noPaddingWithOptionalMaxHeight: (height?: number) => ({
    ".MuiDataGrid-virtualScroller": {
      ...(height ? { maxHeight: `${pxToRem(height)} !important` } : {}),
      overflowY: "auto !important",
      overflowX: "hidden !important",
    },
    ".MuiDataGrid-root .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerRenderZone":
      {
        paddingBottom: 0,
      },
  }),
};

export const inModalDataGridStyle = {
  mt: pxToRem(10),
  display: "flex !important",
  flexDirection: "column !important",
  gap: 2,
  ".MuiDataGrid-root .MuiDataGrid-virtualScroller": {
    height: "auto !important",
    overflowX: "hidden !important",
  },
  ".MuiDataGrid-cellCheckbox.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor":
    {
      borderBottom: "none",
    },
  ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorderColor": {
    borderBottom: "none",
  },
  ".MuiDataGrid-row": {
    mb: pxToRem(1),
    backgroundColor: "greyVariantAlt.100",
    border: "none",
    borderRadius: pxToRem(4),
  },
};

// DataGrid Wrapper [Start]

// Avator [Start]
export const avatorBase = {
  "&.MuiAvatar-colorDefault": {
    backgroundImage: `url(${placeholder})`,
    backgroundSize: "cover",
  },
};
export const profileIconBase = {
  "&.MuiAvatar-colorDefault": {
    backgroundColor: "secondary.main",
  },
};
export const avatarLarge = {
  width: pxToRem(74),
  height: pxToRem(74),

  fontSize: pxToRem(26),
  fontWeight: 600,
  letterSpacing: pxToRem(0.538),

  ".MuiSvgIcon-root[data-testid='AccountCircleIcon']": {
    fontSize: pxToRem(75),
  },
};
export const avatarMedium = {
  width: pxToRem(43),
  height: pxToRem(43),

  fontSize: pxToRem(14),
  fontWeight: 500,
  letterSpacing: pxToRem(0.1),
};

export const avatarSmall = {
  width: pxToRem(27),
  height: pxToRem(27),

  fontSize: pxToRem(12),
  fontWeight: 600,
  letterSpacing: pxToRem(0.249),

  ".MuiSvgIcon-root[data-testid='AccountCircleIcon']": {
    fontSize: pxToRem(29),
  },
};
export const avatarDealerShipStyle = {
  "&.MuiAvatar-colorDefault": {
    backgroundImage: `unset`,
    backgroundColor: "primary.300",
  },
};
// Avator [END]

// input base [Start]
const inputBase = {
  //input wrapper
  ".MuiInputBase-root": {
    "&:not(&.Mui-disabled):not(&.Mui-error)": {
      backgroundColor: themeStyle.palette.grey["A400"],
    },
    border: `${pxToRem(1)} solid ${themeStyle.palette.grey[900]}`,
    borderRadius: pxToRem(4),

    "&:before, &:after": {
      display: "none",
    },

    //hover
    "&:not(&.Mui-focused):not(&.Mui-disabled):hover": {
      backgroundColor: themeStyle.palette.grey["A100"],
    },
    //focus
    "&.Mui-focused": {
      borderColor: themeStyle.palette.primary[400],
      ".MuiInputAdornment-root": {
        visibility: "visible !important",
      },
    },
    //error
    "&.Mui-error": {
      backgroundColor: themeStyle.palette.error[900],
      borderColor: themeStyle.palette.error[400],
    },
    //disabled
    "&.Mui-disabled": {
      backgroundColor: hexToRGBA(themeColorHex("grey[50]"), 0.06),
    },

    //input filed
    ".MuiInputBase-input": {
      color: "greyVariantAlt.700",
      fontSize: pxToRem(14),
      fontWeight: 400,
      lineHeight: pxToRem(17),
      letterSpacing: pxToRem(0.5),
      padding: pxToRem(18, 16, 4),

      "&.MuiInputBase-inputMultiline": {
        padding: pxToRem(0, 4, 4),
      },
    },

    // with hiddenLabel (without label)
    "&.MuiInputBase-hiddenLabel ": {
      ".MuiInputBase-input": {
        padding: pxToRem(12, 16),
      },
    },

    "&.MuiInputBase-adornedStart": {
      ".MuiInputAdornment-root": {
        marginRight: 0,
        paddingBottom: pxToRem(2),
        visibility: "hidden",

        ".MuiTypography-root": {
          color: "greyVariantAlt.700",
          fontSize: pxToRem(14),
        },
      },
      ".MuiInputBase-input": {
        paddingLeft: 0,
      },
    },
  },
  //label
  ".MuiFormLabel-root": {
    // without focuse or filled
    "&.MuiFormLabel-filled, &.Mui-focused": {
      transform: `translate(${pxToRem(13)}, ${pxToRem(4)}) scale(0.78)`,
    },

    // with focuse or filled
    "&:not(&.MuiFormLabel-filled):not(&.Mui-focused)": {
      transform: `translate(${pxToRem(16)}, ${pxToRem(11)}) scale(1)`,
    },
  },
  //helper text
  ".MuiFormHelperText-root": {
    margin: pxToRem(2, 16),
    fontSize: pxToRem(10),
    lineHeight: pxToRem(14),
  },

  ".MuiSelect-select": {
    ".MuiTypography-root": {
      lineHeight: "unset",
    },
  },
};
// input base [END]

// input text field [Start]
export const textFiledStyle = {
  "&.MuiFormControl-root": {
    ...inputBase,
    backgroundColor: "transparent !important",

    ".MuiFormLabel-filled + .MuiInputBase-root .MuiInputAdornment-root": {
      visibility: "visible",
    },
  },
};
// input text field [END]

// select field [Start]
export const selectFiledStyle = {
  "&.MuiFormControl-root": {
    ...inputBase,
  },
};
// select field [END]

// Date Picker field [END]
export const datePickerStyle = {
  "&.MuiFormControl-root": {
    ...inputBase,

    ".MuiOutlinedInput-notchedOutline, .MuiInputBase-root:focus": {
      border: 0,
    },
  },
};
// Date Picker field [ENd]

// Accordance [Start]
export const basicAccordance = {
  "&.MuiAccordion-root": {
    backgroundColor: (theme) => theme.palette.greyVariantAlt[400],
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: 0,
    transition: "all 0.3s ease-in-out",

    "&:not(:last-of-type).Mui-expanded": {
      borderBottomWidth: pxToRem(1),
      borderBottomColor: "greyVariantAlt.800",
      borderBottomStyle: "solid",
    },

    //header
    ".MuiCollapse-root": {
      backgroundColor: themeStyle.palette.grey["A400"],
      width: "100%",

      ".MuiAccordionDetails-root": {
        padding: gutterSpace,
      },
    },

    ".MuiAccordionSummary-root": {
      width: "100%",
      padding: pxToRem(0, 7, 0, 15),
      //content
      ".MuiAccordionSummary-content": {
        my: pxToRem(10),

        //checkbox
        ".MuiCheckbox-root, .MuiRadio-root": {
          padding: pxToRem(0),
          marginRight: pxToRem(22),

          ".MuiSvgIcon-root": {
            fontSize: pxToRem(28),
          },
        },
      },
    },

    //expanded
    "&.Mui-expanded": {
      backgroundColor: (theme) => theme.palette.grey["A400"],
    },
  },

  plainStyle: {
    "&.MuiAccordion-root": {
      backgroundColor: "unset",
      boxShadow: "none",
      borderRadius: 0,
      borderBottomWidth: pxToRem(1),
      borderBottomStyle: "solid",
      borderBottomColor: "greyVariantAlt.900",

      ".MuiAccordionSummary-expandIconWrapper": {
        ".MuiButtonBase-root": {
          color: "grey.50",
        },
      },
    },
  },

  summaryAccordionStyle: {
    "&.MuiAccordion-root": {
      ".MuiButtonBase-root": {
        px: 0,
        minHeight: "auto",
      },
      ".MuiAccordionSummary-content": {
        "&, &.Mui-expanded": {
          margin: pxToRem(10, 0, 0),
        },
      },

      ".MuiAccordionDetails-root": {
        p: 0,
        "> ul": {
          margin: 0,
          padding: 0,
          letterSpacing: pxToRem(0.1),
          li: {
            lineHeight: pxToRem(20),
            listStyle: "none",
            position: "relative",
            marginLeft: pxToRem(15),

            "&:before": {
              content: '""',
              backgroundColor: "grey.50",
              width: pxToRem(5),
              height: pxToRem(5),
              display: "block",
              borderRadius: "50%",
              position: "absolute",
              left: pxToRem(-12),
              top: pxToRem(9),
            },
          },
        },
      },
    },
  },
};
// Accordance [END]

// Plain Summary Style [Start]
export const plainSummaryStyle = {
  padding: pxToRem(15, 14),
  marginTop: pxToRem(4),
  borderRadius: pxToRem(4),

  roleHeading: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    margin: pxToRem(14, 0),
  },

  roleSubHeading: {
    fontSize: pxToRem(14),
    marginBottom: pxToRem(7),
  },

  ul: {
    margin: `0 0 ${gutterSpace}`,
    padding: 0,
    letterSpacing: pxToRem(0.1),
    li: {
      lineHeight: pxToRem(20),
      listStyle: "none",
      position: "relative",
      marginLeft: pxToRem(15),

      "&:before": {
        content: '""',
        backgroundColor: "grey.50",
        width: pxToRem(5),
        height: pxToRem(5),
        display: "block",
        borderRadius: "50%",
        position: "absolute",
        left: pxToRem(-12),
        top: pxToRem(9),
      },
    },
  },
};
// Plain Summary Style [END]

//START Modal Style
export const ModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxHeight: "800px",
  overflow: "auto",
  bgcolor: "background.paper",
  border: `${pxToRem(2)} solid #000`,
  boxShadow: 24,
  p: 4,
};
//END Modal Style

// Dialog Style [Start]
export const dialogStyle = {
  "&.MuiDialog-root": {
    ".MuiPaper-root": {
      padding: pxToRem(12),

      // header
      ".MuiDialogTitle-root": {
        fontSize: pxToRem(24),
        fontWeight: 600,
        letterSpacing: pxToRem(0.497),
        padding: pxToRem(12, 12, 6),
        display: "flex",
        alignItems: "center",
        gap: pxToRem(10),

        ".MuiSvgIcon-root": {
          fontSize: pxToRem(24),
        },
      },

      mainHeading: {
        fontSize: pxToRem(24),
        fontWeight: 600,
        lineHeight: pxToRem(32),
      },
      subHeading: {
        fontSize: pxToRem(16),
        fontWeight: 500,
        lineHeight: pxToRem(24),
        letterSpacing: pxToRem(0.15),
      },

      // content
      ".MuiDialogContent-root": {
        padding: pxToRem(6, 12),
      },

      // footer
      ".MuiDialogActions-root": {
        padding: pxToRem(12),
      },
    },
  },
};
// Dialog Style [END]

// Vertical Tab [Start]
export const verticalTab = {
  // List Style
  listStyle: {
    backgroundColor: (theme) => theme.palette.greyVariantAlt[600],
    padding: pxToRem(6, 0),
    margin: 0,
    width: "100%",
    borderTopLeftRadius: pxToRem(4),
    borderBottomLeftRadius: pxToRem(4),

    ".MuiListItem-root": {
      position: "relative",
      transition: "all 0.3s ease-in-out",

      "&:before": {
        content: '""',
        backgroundColor: "secondary.main",
        width: pxToRem(3),
        height: "100%",
        display: "block",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 1,
        opacity: 0,
      },

      ".MuiFormControlLabel-root": {
        width: "100%",

        ".MuiFormControlLabel-label": {
          fontSize: pxToRem(12),
          fontWeight: 500,
          lineHeight: pxToRem(16),
          letterSpacing: pxToRem(0.5),
          color: "grey.50",
        },
      },

      "&:hover, &.selected-tab": {
        backgroundColor: "grey.A400",
        "&:before": { opacity: 1 },
      },
    },
  },
};
// Vertical Tab [END]

// Error Message [Start]
export const errorMsgStyle = {
  minWidth: "100%",
  minHeight: "100vh",
  textAlign: "center",

  ".grphicHolder": {
    width: "100%",
    maxWidth: pxToRem(385),
    marginBottom: pxToRem(60),
  },
  ".errorMsg": {
    maxWidth: pxToRem(674),
    color: "greyVariantAlt.700",
    fontSize: pxToRem(28),
    lineheight: pxToRem(36),
    fontWeight: "normal",
    margin: 0,

    ".errorCode": {
      fontWeight: 700,
    },
  },
};
// Error Message [END]

// image upload Box [Start]
export const uploadBox = {
  height: pxToRem(395),
  borderWidth: pxToRem(3),
  borderStyle: "dashed",
  borderColor: "grey.700",
  borderRadius: pxToRem(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  position: "relative",
};
// image upload Box [END]

// slider black [Start]
export const sliderBlack = {
  color: "rgba(0,0,0,0.87)",
  height: pxToRem(1),
  marginX: gutterSpace,

  "& .MuiSlider-thumb": {
    width: pxToRem(16),
    height: pxToRem(16),
    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    "&::before": {
      boxShadow: `0 ${pxToRem(2, 12)} 0 rgba(0,0,0,0.4)`,
    },
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0 0 0 ${pxToRem(8)} rgb(0 0 0 / 16%)'
                }`,
    },
    "&.Mui-active": {
      width: pxToRem(20),
      height: pxToRem(20),
    },
  },
  "& .MuiSlider-rail": {
    opacity: 0.28,
  },
};
// slider black [END]
// Listing Toggler [Start]
export const viewToggler = {
  "&.MuiToggleButtonGroup-root": {
    ".MuiToggleButton-root": {
      border: "0",
      borderRadius: "50%",
      color: "grey.50",
      padding: pxToRem(4),
      position: "relative",

      "&:first-of-type": {
        marginRight: pxToRem(8),
      },

      "&:not(:first-of-type)": {
        margin: pxToRem(0, 8),

        "&:before": {
          content: "''",
          height: "100%",
          color: "grey.50",
          borderLeftWidth: pxToRem(1),
          borderLeftStyle: "solid",
          borderleftColor: "grey.50",
          position: "absolute",
          left: pxToRem(-8),
          top: 0,
          display: "block",
        },
      },

      //selected
      "&.Mui-selected": {
        backgroundColor: "grey.50",
        color: "grey.A400",
      },
    },
  },
};
// Listing Toggler [END]

// Status Flag [Start]
const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const statusFlagStyle = {
  width: pxToRem(25),
  height: pxToRem(25),
  fontSize: pxToRem(14),
  borderWidth: pxToRem(1),
  borderStyle: "solid",
  borderRadius: "50%",
  position: "relative",

  ".MuiSvgIcon-root": {
    fontSize: "inherit",
    transform: "translate(calc(100% - 68%), calc(100% - 75%))",

    "&[data-testid='RotateRightIcon']": {
      animation: `${rotateAnimation} 0.6s infinite linear`,
      position: "relative",
      left: "calc(100% - 18px)",
      top: "calc(100% - 19px)",
    },
  },
};
// Status Flag [END]

//Image Box Style [Start]
export const imageBoxStyle = {
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: pxToRem(4),
  overflow: "hidden",
  position: "relative",
};
//Image Box Style [END]

// Listing Card [Start]
export const listingCardStyle = {
  width: pxToRem(250),
  padding: pxToRem(12, 10),
  boxShadow: `0 ${pxToRem(1)} ${pxToRem(3)} ${pxToRem(1)} ${hexToRGBA(
    themeColorHex("grey[50]"),
    0.15
  )}, 0 ${pxToRem(1)} ${pxToRem(2)} 0 ${hexToRGBA(
    themeColorHex("grey[50]"),
    0.3
  )}`,

  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: pxToRem(4),

    ".MuiTypography-root": {
      fontSize: pxToRem(14),
      fontWeight: 500,
      letterSpacing: pxToRem(0.1),
      lineHeight: pxToRem(20),
    },
  },

  Content: {
    ".heading": {
      fontWeight: 500,
      lineHeight: pxToRem(24),
      letterSpacing: pxToRem(0.15),
      marginTop: pxToRem(8),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  Footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: pxToRem(19),

    ".pricing": {
      fontWeight: 500,
      lineHeight: pxToRem(24),
      letterSpacing: pxToRem(0.15),
    },
  },
};
// Listing Card [END]

// Expandable Heading Style [Start]
export const ExpandableHeadingStyle = {
  "&.MuiAccordion-root": {
    backgroundColor: "unset",
    margin: 0,
    position: "relative",

    "&:before": { display: "none" },

    ".MuiAccordionSummary-root": {
      minHeight: "auto",
      margin: `${pxToRem(11)} auto`,
    },

    ".MuiAccordionSummary-root, .MuiAccordionDetails-root": {
      padding: 0,
    },

    ".MuiAccordionSummary-content": {
      fontSize: pxToRem(14),
      fontWeight: 600,
      lineHeight: pxToRem(33),
      letterSpacing: pxToRem(0.1),
      margin: 0,

      "&:after": {
        content: "''",
        backgroundColor: "greyVariantAlt.800",
        height: pxToRem(1),
        flex: 1,
        margin: pxToRem(7, 17, 0),
        alignSelf: "center",
      },
    },
    ".MuiAccordionSummary-expandIconWrapper": {
      marginTop: `${pxToRem(7)}`,
    },

    // additional button
    buttonContainer: {
      position: "absolute",
      right: pxToRem(33),
      top: 0,
      zIndex: 1,

      ".MuiButton-root": {
        width: "auto !important",
        color: "grey.50",
        fontWeight: "normal",
        padding: pxToRem(7, 14),

        // all state bg
        "&, &:hover, &:focus, &:disabled, &:not(&.Mui-disabled)": {
          backgroundColor: "greyVariantAlt.100",
          boxShadow: "none",
        },
      },
    },

    noExpandable: {
      ".MuiAccordionSummary-root": {
        pointerEvents: "none",
      },

      ".MuiAccordionSummary-content:after": {
        marginRight: "0 !important",
      },
      ".MuiAccordionSummary-expandIconWrapper": {
        display: "none",
      },
    },
  },
};
// Expandable Heading Style [END]

// Simple Dash heading Style [Start]
export const simpleDashheadingStyle = {
  marginBottom: pxToRem(11.5),
  display: "flex",
  alignItems: "center",
  height: pxToRem(44),
  headingStyle: {
    display: "flex",
    flex: "1",
    ...ExpandableHeadingStyle["&.MuiAccordion-root"][
      ".MuiAccordionSummary-content"
    ],
  },
};
// Simple Dash heading Style [END]

// Dealer shipTreeStyle [Start]
export const dealershipTreeStyle = {
  "& > .MuiTreeItem-root": {
    //default tile style
    "& .MuiTreeItem-content": {
      backgroundColor: "greyVariantAlt.400",
      marginBottom: pxToRem(22),
      borderTopRightRadius: pxToRem(5),
      borderBottomRightRadius: pxToRem(5),
      transition: "all 0.3s ease-in-out",

      "&:hover": {
        boxShadow: `0 ${pxToRem(3, 5)} ${hexToRGBA(
          themeColorHex("grey[50]"),
          0.15
        )}`,
      },
    },
    //level-1 tile style
    "& > .MuiTreeItem-content": {
      "&, &:hover, &.Mui-selected, &.Mui-focused": {
        backgroundColor: themeStyle.palette.primary[300],
        color: themeStyle.palette.grey.A400,

        ".circle": {
          color: themeStyle.palette.primary[300],
        },
      },

      // //level-2 tile style
      "& + .MuiTreeItem-group": {
        position: "relative",
        marginLeft: pxToRem(47),

        //vertical border
        "&:before": {
          content: "''",
          backgroundColor: "greyVariantAlt.800",
          width: pxToRem(1),
          height: `calc(100% - ${pxToRem(42)})`,
          position: "absolute",
          left: pxToRem(-47),
          top: pxToRem(-22),
        },

        "& > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner": {
          "& > .MuiTreeItem-root": { position: "relative" },
          "& > .MuiTreeItem-root > .MuiTreeItem-content": {
            position: "relative",

            //horizontal border
            "&:before": {
              content: "''",
              backgroundColor: "greyVariantAlt.800",
              width: pxToRem(47),
              height: pxToRem(1),
              position: "absolute",
              left: pxToRem(-47),
              top: pxToRem(40),
            },

            "&, &:hover, &.Mui-selected, &.Mui-focused": {
              backgroundColor: themeStyle.palette.secondary[600],
              color: themeStyle.palette.grey.A400,
            },

            ".circle": {
              color: themeStyle.palette.secondary[600],
            },
            ".MuiTreeItem-iconContainer": {
              backgroundColor: "greyVariantAlt.100",
              color: "greyVariantAlt.800",
              margin: 0,
              position: "absolute",
              left: pxToRem(-31),
            },
            //level-3 tile style
            "& + .MuiTreeItem-group": {
              position: "relative",
              marginLeft: pxToRem(24),

              "&:before": {
                content: "''",
                backgroundColor: "greyVariantAlt.800",
                width: pxToRem(1),
                height: `calc(100% - ${pxToRem(42)})`,
                position: "absolute",
                left: pxToRem(-24),
                top: pxToRem(-22),
              },

              "& > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner": {
                "& > .MuiTreeItem-root > .MuiTreeItem-content": {
                  position: "relative",

                  "&:before": {
                    content: "''",
                    backgroundColor: "greyVariantAlt.800",
                    width: pxToRem(24),
                    height: pxToRem(1),
                    position: "absolute",
                    left: pxToRem(-24),
                    top: pxToRem(40),
                  },

                  "&, &:hover, &.Mui-selected, &.Mui-focused": {
                    backgroundColor: themeStyle.palette.primary[900],
                    color: themeStyle.palette.secondary[600],
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export const dealershipTileStyle = {
  padding: pxToRem(16, 3),
  display: "flex",
  alignItems: "center",

  circle: {
    backgroundColor: themeStyle.palette.grey["A400"],
    width: pxToRem(49),
    height: pxToRem(49),
    text0Transform: "uppercase",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    shortTitle: {
      fontSize: pxToRem(26),
      letterSpacing: pxToRem(0.538),
      lineHeight: 1,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    locationPrefix: {
      fontSize: pxToRem(12),
      lineHeight: 1,
      fontWeight: 600,
      textTransform: "uppercase",
    },
  },

  title: {
    marginLeft: pxToRem(17),
    flex: 1,
    main: {
      fontSize: pxToRem(18),
      fontWeight: 700,
      lineHeight: "150%",
    },
    description: {
      fontSize: pxToRem(16),
      fontWeight: "normal",
    },
  },
};
// Dealer shipTreeStyle [END])

// Contact Card Style [Start]
export const contactCardInfoStyle = {
  backgroundColor: "grey.A400",
  minWidth: pxToRem(300),
  color: "grey.50",
  boxShadow: `0 ${pxToRem(1, 3, 1)} ${hexToRGBA(
    themeColorHex("grey[50]"),
    0.15
  )}, 0 ${pxToRem(1, 2)} 0 ${hexToRGBA(themeColorHex("grey[50]"), 0.3)}`,
  borderRadius: pxToRem(4),

  contentWrapper: {
    width: "100%",
    padding: pxToRem(8, 18),
  },

  listStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    icon: {
      color: "grey.100",
      fontSize: pxToRem(12),
      marginRight: pxToRem(6),
      display: "flex",
      alignItmes: "center",
      justifyContent: "center",
      ".MuiSvgIcon-root": {
        width: pxToRem(16),
      },
    },

    paragraphy: {
      fontSize: pxToRem(12),
      lineHeight: pxToRem(14),
      letterSpacing: pxToRem(0.25),
    },
  },
  subTitleIcon: {
    color: "primary.main",
    marginRight: pxToRem(4),
  },
  subTitle: {
    fontSize: pxToRem(10),
  },

  titleStyle: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: pxToRem(24),
    letterSpacing: pxToRem(0.15),
    overflow: "hidden",
    maxWidth: "90%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  ButtonColStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderLeftWidth: pxToRem(1),
    borderLeftStyle: "solid",
    borderLeftColor: "greyVariantAlt.900",

    ".MuiButtonBase-root": {
      borderRadius: "unset",
      width: "100%",

      "&:not(&:last-of-type)": {
        borderBottomWidth: pxToRem(1),
        borderBottomStyle: "solid",
        borderBottomColor: "greyVariantAlt.900",
      },
    },
  },

  verifiedBadge: {
    display: "flex",
    marginTop: pxToRem(-2),
    marginLeft: pxToRem(2),

    verified: {
      color: "supportingColor.200",
    },
    unVerified: {
      color: "supportingColor.A400",
    },
  },
};
// Contact Card Style [END]

// Deal Number Style [Start]
export const dealNumberStyle = {
  backgroundColor: "greyVariantAlt.600",
  display: "flex",
  alignItems: "center",
  borderRadius: pxToRem(4),
  color: "greyVariantAlt.800",

  iconLeft: {
    padding: pxToRem(8),
    margin: pxToRem(0, 4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    label: {
      fontSize: pxToRem(10),
      lineHeight: "normal",
    },
    input: { padding: 0, letterSpacing: pxToRem(0.5) },
  },
  btnCopy: {
    height: pxToRem(44),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "unset",
  },
};
// Deal Number Style [END]

// Summary Card Style [Start]
export const summaryCardStyle = {
  borderRadius: pxToRem(4),
  padding: pxToRem(17, 19, 20),
  marginTop: pxToRem(7),
  maxHeight: `calc(100vh - ${pxToRem(98)})`,
  overflow: "auto",

  mainHeading: {
    fontWeight: 500,
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(0.414),
  },
  contentWrapper: {
    color: "grey.50",
    marginTop: pxToRem(13),
  },
  locationChipStyle: {
    backgroundColor: "supportingColor.A700",
    maxWidth: pxToRem(150),
    height: "auto",
    color: "grey.A400",
    fontSize: pxToRem(12),
    letterSpacing: pxToRem(0.5),
    padding: pxToRem(4, 0),

    ".MuiChip-label": {
      lineHeight: "normal",
    },
  },
};
// Summary Card Style [END]

// TextEditor Wrapper Style [Start]
export const textEditorWrapper = {
  ".tox-tinymce": {
    borderRadius: pxToRem(4),
    border: "unset",
  },
  ".tox-editor-container": {
    backgroundColor: "grey.A400",
  },
  ".tox:not(.tox-tinymce-inline) .tox-editor-header": {
    borderBottom: `${pxToRem(1)} solid ${themeColorHex("greyVariantAlt[900]")}`,

    margin: pxToRem(0, 8),
    boxShadow: "unset",
  },
};
// TextEditor Wrapper Style [END]

// Clock Header Style [Start]
export const clockHeaderStyle = {
  width: "auto",
  margin: pxToRem(0, 22, 17),
  paddingBottom: pxToRem(12),
  borderBottom: `${pxToRem(1)} solid ${themeColorHex("greyVariantAlt[800]")}`,

  mainWrapper: {
    alignItems: "center",
    flexDirection: "row",
  },
  logoWrapper: {
    marginRight: pxToRem(21),
  },
  mainHeading: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: pxToRem(0.15),
    paddingBottom: pxToRem(12),
    alignSelf: "flex-end",
  },
};
// Clock Header Style [END]

// Time Clock Card Atom [Start]
export const timeClockCardAtomStyle = {
  cardStyle: {
    borderRadius: pxToRem(4),
    padding: pxToRem(9, 17),
    display: "flex",
    alignItems: "center",
    gap: pxToRem(32),

    heading: {
      fontSize: pxToRem(14),
      fontWeight: 500,
      lineHeight: pxToRem(20),
      letterSpacing: pxToRem(0.1),
      marginBottom: pxToRem(4),
    },
    time: {},
    input: {
      ".MuiInputBase-input": {
        padding: 0,
        fontSize: pxToRem(24),
        lineHeight: pxToRem(32),
      },
      ".MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    button: {
      width: pxToRem(40),
      height: pxToRem(40),
      boxShadow: "unset",

      "&:not(:disabled)": {
        backgroundColor: "success.400",
      },
    },
  },
};
// Time Clock Card Atom [END]

//Time Clock Calendar Style [Start]
export const timeClockCalendarStyle = {
  ".MuiDateRangeCalendar-root": {
    backgroundColor: "greyVariantAlt.600",
    borderRadius: pxToRem(4),

    ".MuiDateRangeCalendar-monthContainer": {
      width: "100%",

      ".MuiPickersCalendarHeader-label": {
        color: "greyVariantAlt.800",
        fontSize: pxToRem(14),
        fontWeight: 600,
        lineHeight: pxToRem(20),
        letterSpacing: pxToRem(0.1),
      },

      ".MuiPickersArrowSwitcher-root": {
        ".MuiButtonBase-root": {
          color: "greyVariantAlt.800",
        },
      },

      ".MuiDayCalendar-root": {
        color: "greyVariantAlt.700",

        ".MuiButtonBase-root": {
          paddingTop: pxToRem(2),
        },
        // when day/s is selected
        ".MuiDateRangePickerDay-rangeIntervalDayHighlight": {
          backgroundColor: "secondary.A100",
          ".MuiButtonBase-root": {
            color: "secondary.200",
          },

          // when day is selected with ranage start or ending
          "&.MuiDateRangePickerDay-rangeIntervalDayHighlightStart, &.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd":
            {
              ".MuiButtonBase-root.Mui-selected": {
                backgroundColor: "secondary.main",
                color: "grey.A400",
              },
            },
        },
        // current date
        ".MuiButtonBase-root.MuiPickersDay-today:not(&.Mui-selected)": {
          borderColor: "secondary.main",
          color: "secondary.main",
        },
      },
    },
  },
};
//Time Clock Calendar Style [END]

// Dispatch Calendar View Style [Start]
export const dispatchCalendarViewStyle = {
  ".MuiDateCalendar-root": {
    backgroundColor: "greyVariantAlt.600",
    width: "100%",
    height: "unset",
    maxHeight: "unset",
    padding: pxToRem(10, 0, 20),
    borderRadius: pxToRem(4),
    boxShadow: `0 ${pxToRem(1, 2)} 0 ${hexToRGBA(
      themeColorHex("grey[50]"),
      0.3
    )}, 0 ${pxToRem(1, 3, 1)} ${hexToRGBA(themeColorHex("grey[50]"), 0.15)}`,

    ".MuiPickersCalendarHeader-root": {
      padding: pxToRem(4, 16),
      margin: `${pxToRem(20)} 0`,
    },
    ".MuiPickersCalendarHeader-label": {
      color: "greyVariantAlt.800",
      fontWeight: 600,
      letterSpacing: pxToRem(0.1),
      lineHeight: pxToRem(20),
    },
    ".MuiDayCalendar-header, .MuiDayCalendar-weekContainer": {
      justifyContent: "space-around",
      paddingY: gutterSpace,
      margin: 0,
    },
    ".MuiDayCalendar-header .MuiDayCalendar-weekDayLabel, .MuiDayCalendar-weekContainer .MuiPickersDay-root":
      {
        width: pxToRem(40),
        height: pxToRem(40),
        fontSize: pxToRem(14),
        lineHeight: pxToRem(17),
        letterSpacing: pxToRem(0.5),
        color: "greyVariantAlt.700",
      },
    ".MuiPickersDay": {
      "&-root": {
        border: `${pxToRem(1)} transparent solid`,
        "&.Mui-highlightedDay": {
          backgroundColor: "tertiary.A100",
          color: `${themeColorHex("secondary[400]")} !important`,
          borderColor: "secondary.400",
        },
        "&.Mui-selected": {
          backgroundColor: "secondary.400",
          color: `${themeColorHex("grey[A400]")} !important`,
        },
      },
    },
  },
};
// Dispatch Calendar View Style [END]

// Table Wrapper [Start]
export const TableWrapperStyle = {
  borderRadius: pxToRem(4),
  padding: pxToRem(8, 17),
};

export const inlineTableSelectStyle = {
  border: "none !important",
  backgroundColor: "inherit !important",
  ".MuiSelect-select.MuiSelect-filled": {
    px: "0 !important",
    color: `${themeColorHex("grey[50]")} !important`,
  },
  "p.MuiTypography-root": {
    fontSize: pxToRem(14),
  },
  "svg.MuiSvgIcon-root": {
    right: pxToRem(-7),
    color: "grey.50",
  },
};
// Table Wrapper [END]

// Divider Accordain Style [Start]
export const DividerAccordionStyle = {
  "&.MuiAccordion-root": {
    backgroundColor: "unset",
    margin: 0,
  },
  ".MuiAccordionSummary-root": { display: "none" },
  ".MuiAccordionDetails-root": {
    padding: 0,
  },
  dividerStyle: {
    paddingBottom: pxToRem(1),
    ".MuiDivider-wrapper": {
      padding: 0,
    },
  },
  btnAccord: {
    backgroundColor: "greyVariantAlt.100",
    width: pxToRem(12),
    height: pxToRem(16),
    color: "grey.400",
    padding: 0,
    borderRadius: pxToRem(68),
    transform: `translateY(${pxToRem(-1)})`,
    boxShadow: `0 ${pxToRem(1, 2)} 0 ${hexToRGBA(
      themeColorHex("grey[50]"),
      0.3
    )}, 0 ${pxToRem(1, 3, 1)} ${hexToRGBA(themeColorHex("grey[50]"), 0.15)}`,
  },
};
// Divider Accordain Style [END]

// Card Black Style [Start]
export const cardBlackStyle = {
  backgroundColor: "grey.A400",
  color: "greyVariant.200",
  width: "100%",
  padding: pxToRem(10, 20),
  borderRadius: pxToRem(4),
  flexDirection: "row",
  alignItems: "center",

  darkMode: {
    backgroundColor: "greyVariant.200",
    color: "grey.A400",
  },

  heading: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: "normal",
    wordBreak: "break-all",
  },
  content: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(14),
    letterSpacing: pxToRem(0.25),
  },
};
// Card Black Style [END]

// Timelines Style [Start]
// History Timeline Style [Start]
const timelineContent = {
  color: "greyVariantAlt.700",
  fontSize: pxToRem(12),
  letterSpacing: `${pxToRem(0.12)}`,
  paddingX: gutterSpace,
};

export const historyTimelineStyle = {
  ".MuiTimelineOppositeContent-root": {
    ...timelineContent,
    display: "flex",
    flex: 0.2,
    justifyContent: "flex-end",
    paddingX: gutterSpace,
  },

  ".MuiTimelineSeparator-root": {
    ".MuiTimelineConnector-root": {
      backgroundColor: "unset",
      width: pxToRem(1),
      height: pxToRem(67),
      borderLeft: `${pxToRem(1)} dashed ${themeColorHex("greyVariantAlt[50]")}`,
    },
  },

  ".MuiTimelineContent-root": {
    ...timelineContent,
  },

  IconDot: {
    ".MuiTimelineDot-root": {
      backgroundColor: "unset",
      border: "unset",
      padding: 0,
      margin: `${pxToRem(8)} auto`,
      boxShadow: "unset",

      ".MuiBox-root": {
        width: pxToRem(35),
        height: pxToRem(35),
        fontSize: pxToRem(18),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        ".MuiSvgIcon-root": {
          transform: `translateX(${pxToRem(-0.5)})`,
        },
      },
    },
  },

  title: {
    fontWeight: 600,
    lineHeight: pxToRem(20),
  },

  trailDot: {
    margin: `0 ${pxToRem(11.5)}`,

    ".MuiTimelineDot-root": {
      backgroundColor: "greyVariantAlt.800",
      margin: `${pxToRem(6)} auto`,
      boxShadow: "unset",
    },
  },
};
// History Timeline Style [END]
// Timelines Style [END]

// Dispatch Summary Style [Start]
export const dispatchSummaryStyle = {
  padding: pxToRem(20),
  marginBottom: pxToRem(3),

  // timeline
  timeline: {
    padding: 0,
    margin: pxToRem(0, 0, 0, 17),
    "& .MuiTimelineItem-root": {
      minHeight: "auto",

      "&.MuiTimelineItem-missingOppositeContent": {
        "&:before": {
          display: "none",
        },
      },
      ".MuiTimelineSeparator-root": {
        marginTop: pxToRem(4),
        ".MuiTimelineDot-root": {
          backgroundColor: "unset",
          margin: 0,
          padding: 0,
          border: "none",
          boxShadow: "unset",
        },
        ".MuiTimelineConnector-root": {
          backgroundColor: "unset",
          width: pxToRem(1),
          height: pxToRem(48),
          borderLeft: `${pxToRem(1)} dashed ${themeColorHex(
            "greyVariantAlt[50]"
          )}`,
          transform: `translateX(${pxToRem(-0.5)})`,
        },
      },
      ".MuiTimelineContent-positionRight": {
        color: "greyVariantAlt.700",
        fontSize: pxToRem(12),
        lineHeight: "normal",
        letterSpacing: pxToRem(0.12),
        padding: pxToRem(0, 0, 0, 13),
      },
    },

    heading: {
      color: "greyVariant.100",
      fontWeight: 600,
      lineHeight: pxToRem(24),
      letterSpacing: pxToRem(0.15),
    },
    details: {},
  },

  // timeline
  summrayTimeline: {
    padding: 0,
    marginLeft: pxToRem(7),
    "& .MuiTimelineItem-root": {
      minHeight: "auto",

      "&.MuiTimelineItem-missingOppositeContent": {
        "&:before": {
          display: "none",
        },
      },
      ".MuiTimelineSeparator-root": {
        ".MuiTimelineDot-root": {
          backgroundColor: "secondary.400",
          color: "grey.A400",
          width: pxToRem(38),
          height: pxToRem(38),
          margin: 0,
          padding: 0,
          border: "none",
          borderRadius: "50%",
          boxShadow: "unset",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          ".MuiSvgIcon-root": {
            transform: "translateY(-2%)",
          },
        },

        ".MuiTimelineConnector-root": {
          backgroundColor: "unset",
          width: pxToRem(1),
          height: pxToRem(48),
          borderLeft: `${pxToRem(1)} dashed ${themeColorHex(
            "greyVariantAlt[50]"
          )}`,
          transform: `translateX(${pxToRem(-0.5)})`,
          position: "relative",

          ".MuiSvgIcon-root": {
            color: "greyVariantAlt.50",
            position: "absolute",
            left: pxToRem(-12),
            bottom: pxToRem(-9),
            transform: `translateX(-2%)`,
          },
        },
      },
      ".MuiTimelineContent-positionRight": {
        color: "grey.50",
        fontSize: pxToRem(12),
        lineHeight: "normal",
        letterSpacing: pxToRem(0.12),
        padding: pxToRem(0, 0, 0, 13),
      },
    },
    ".MuiTimelineItem-root:last-of-type": {
      ".MuiTimelineDot-root": {
        backgroundColor: "secondary.A100",
        color: "secondary.400",
      },
    },

    heading: {
      color: "greyVariant.100",
      fontWeight: 600,
      lineHeight: pxToRem(24),
      letterSpacing: pxToRem(0.15),
    },
  },
  // Selected users
  selectedUsers: {
    marginLeft: pxToRem(3),
    usericon: {
      width: pxToRem(46),
      height: pxToRem(46),

      ".MuiSvgIcon-root": {
        color: "supportingColor.400",
        width: "100%",
        height: "100%",
        margin: `${pxToRem(5, 2)} 0 0`,
      },
    },
  },

  //notes
  notes: {
    heading: {
      fontSize: pxToRem(18),
      fontWeight: 700,
      letterSpacing: pxToRem(0.18),
    },
    item: {
      paddingY: gutterSpace,
      "&:not(&:last-of-type)": {
        borderBottom: `${pxToRem(1)} dashed ${themeColorHex(
          "greyVariantAlt[50]"
        )}`,
      },

      author: {
        fontSize: pxToRem(14),
        fontWeight: 500,
        lineHeight: pxToRem(20),
        letterSpacing: pxToRem(0.1),
        flex: 1,
      },
      timestampe: {
        fontSize: pxToRem(10),
        lineHeight: pxToRem(14),
        display: "flex",
        alignItems: "center",

        ".MuiSvgIcon-root": {
          color: "secondary.400",
          fontSize: pxToRem(14),
          marginLeft: pxToRem(7),
        },
      },
      message: {
        color: "greyVariant.500",
        fontSize: pxToRem(10),
        fontWeight: 400,
        lineHeight: pxToRem(14),
      },
    },
    messageBox: {
      position: "relative",

      ".MuiFormControl-root .MuiInputBase-root": {
        ".MuiInputBase-input": {
          padding: `${pxToRem(8, 40, 8, 0)} !important`,
        },
      },
    },
    messageSubmit: {
      backgroundColor: "tertiary.900",
      color: "grey.A400",
      borderRadius: pxToRem(4),
      position: "absolute",
      bottom: pxToRem(7),
      right: pxToRem(7),
      boxShadow: `0 ${pxToRem(1)} ${pxToRem(2)} 0 ${hexToRGBA(
        themeColorHex("grey[50]"),
        0.3
      )}, 0 ${pxToRem(1)} ${pxToRem(3)} ${pxToRem(1)} ${hexToRGBA(
        themeColorHex("grey[50]"),
        0.15
      )}`,
    },
  },
  //summary
  summary: {
    heading: {
      fontSize: pxToRem(20),
      fontWeight: 600,
      lineHeight: pxToRem(20),
      letterSpacing: pxToRem(0.414),
      marginBottom: pxToRem(4),
    },
    subHeading: {
      fontSize: pxToRem(12),
      fontWeight: 600,
      lineHeight: pxToRem(16),
      letterSpacing: pxToRem(0.5),
      marginBottom: pxToRem(13),
    },
  },
};
// Dispatch Summary Style [END]

// User Card Tyle Style [Start]
export const UserTileCardStyle = {
  margin: pxToRem(3),
  display: "flex",
  alignItems: "center",
  minHeight: pxToRem(51),
};
// User Card Tyle Style [END]
export const bottomInfoStyle = {
  position: "sticky",
  padding: "18px 18px 18px 0",
  bottom: 20,
  color: "white",
  margin: 0,
  itemOne: {
    textAlign: "right",
    padding: "18px",
    bgcolor: themeColorHex("greyVariant[200]"),
    borderRadius: "5px",
  },
  itemTwo: {
    borderRadius: "10px",
    borderLeft: "solid 5px",
    bgcolor: themeColorHex("greyVariant[200]"),
    paddingLeft: "20px",
  },
};

// Customm Chip Style [Start]
export const customChipStyle = {
  height: "auto",
  padding: pxToRem(2, 9),
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  ".MuiChip-label": {
    fontWeight: 600,
    fontSize: pxToRem(12),
    letterSpacing: pxToRem(0.5),
    lineHeight: "normal",
    padding: 0,
  },
};
export const chipGeneratorStyle = {
  ".MuiMenu-paper": {
    width: "100%",
    maxWidth: pxToRem(505),
    padding: pxToRem(26),
  },
  header: {
    marginBottom: pxToRem(21),
    ".MuiSvgIcon-root, .MuiTypography-root": {
      fontSize: pxToRem(24),
    },
    ".MuiTypography-root": {
      fontWeight: "bold",
    },
  },
  subHeading: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: pxToRem(24),
    letterSpacing: pxToRem(0.15),
    marginBottom: pxToRem(12),
  },

  radioBox: {
    margin: 0,
    ".MuiButtonBase-root": {
      display: "none",
    },
    ".MuiBox-root": {
      width: pxToRem(38),
      height: pxToRem(38),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    ".MuiSvgIcon-root": {
      fontSize: "unset",
      color: "unset",
      opacity: 0,
      transition: "opacity 0.15s ease-in-out",
    },

    ".MuiRadio-root ~ .MuiTypography-root": {
      ".MuiBox-root": {
        boxShadow: `inset 0 0 0 0 ${themeColorHex("grey[A400]")}`,
        transition: "box-shadow 0.15s ease-in-out",
      },
    },

    ".MuiRadio-root.Mui-checked ~ .MuiTypography-root": {
      ".MuiBox-root": {
        boxShadow: `inset 0 0 0 ${pxToRem(3)} ${themeColorHex("grey[A400]")}`,
        transition: "box-shadow 0.15s ease-in-out",
      },

      ".MuiSvgIcon-root": {
        opacity: 1,
      },
    },
  },

  previewBox: {
    width: pxToRem(180),
    height: pxToRem(203),
    padding: gutterSpace,
    display: "flex",
    justifyContent: "center",
    gap: gutterSpace,

    ".MuiSvgIcon-root": {
      fontSize: "unset",
      color: "unset",
    },
  },
};
// Customm Chip Style [END]

// Content Card Style [Start]
export const contentCardStyle = {
  icon: {
    color: "grey.100",
  },
  ".MuiTypography-root": {
    fontWeight: 600,
    letterSpacing: pxToRem(0.15),
    lineHeight: pxToRem(24),
  },
};
// Content Card Style [END]

// Day Selector Style [Start]
export const daysSelectorStyle = {
  dayWrapper: {
    width: pxToRem(43),
    height: pxToRem(43),
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",

    ".MuiTypography-root": {
      color: "grey.50",
      fontWeight: 300,
    },
    ".MuiCheckbox-root": {
      width: pxToRem(43),
      height: pxToRem(43),
      position: "absolute",
      left: "50%",
      top: "50%",
      zIndex: 1,
      transform: `translate(-50%, -50%)`,
      opacity: 0,
    },

    selected: {
      backgroundColor: "primary.main",
      ".MuiTypography-root": {
        color: "grey.A400",
      },
    },
  },
};
// Day Selector Style [END]

// Vehical Info Card [Start]
export const VehicalInfoCardStyle = {
  backgroundColor: "grey.100",
  maxWidth: pxToRem(533),
  color: "grey.A400",
  borderRadius: pxToRem(4),
  padding: pxToRem(10, 12),
  gap: pxToRem(14),

  ".status": {
    fontSize: pxToRem(10),
    color: "grey.A400",
    display: "flex",
    alignItems: "center",

    ".MuiSvgIcon-root": {
      fontSize: pxToRem(16),
      color: "grey.700",
    },
  },

  ".title": {
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(24),
    letterSpacing: pxToRem(0.15),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};
// Vehical Info Card [END]

// ExtendedViewRow Style
export const extendedViewRowStyle = {
  overlayBox: {
    position: "absolute",
    top: 0,
    right: -5,
    width: pxToRem(191),
    height: "100%",
    mt: pxToRem(9),
    textAlign: "end",
    background: `linear-gradient(90.91deg, rgba(246, 246, 246, 0) 8.67%, ${themeColorHex(
      "greyVariantAlt[100]"
    )} 48.02%)`,
  } as SxProps,
  fabButton: {
    backgroundColor: "grey.A400",
    color: "grey.50",
    borderRadius: pxToRem(16),
    width: pxToRem(98),
    mt: pxToRem(8),
    fontSize: pxToRem(16),
    height: "86%",
    boxShadow:
      "0 1px 3px 1px rgba(0, 0, 0, 0.15),0 1px 2px 0 rgba(0, 0, 0, 0.3)",
  } as SxProps,
};

// Cancel Button Design
export const cancelBtnStyle: SxProps = {
  color: `${hexToRGBA(themeColorHex("grey[50]"), 0.26)} !important`,
  backgroundColor: `${hexToRGBA(themeColorHex("grey[50]"), 0.12)} !important`,
};

export const textOverflowEllipsis = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const dealerInfoTileStyle = {
  p: `${pxToRem(11.5)} ${pxToRem(17)}`,
  borderRadius: pxToRem(4),
  backgroundColor: "secondary.A400",
  color: "grey.A400",
  checkedIconWrapper: {
    width: pxToRem(24),
    height: pxToRem(24),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    checkedIcon: {
      color: "white",
      fontSize: pxToRem(18),
      backgroundColor: "secondary.main",
      borderRadius: pxToRem(4),
      ml: pxToRem(-6),
    },
  },
  rightButtonStyle: {
    textDecoration: "underline",
    height: pxToRem(14),
    fontSize: pxToRem(10),
    fontWeight: 400,
    textAlign: "right",
    pr: 0,
  },
};

export const boxShadow = {
  elevation1: `0 ${pxToRem(1, 2)} 0 ${hexToRGBA(
    themeColorHex("grey[50]"),
    0.3
  )}, 0 ${pxToRem(1, 3, 1)} ${hexToRGBA(themeColorHex("grey[50]"), 0.15)}`,
};

export const slimGreyBtnShadow = {
  ...slimGreyBtn,
  boxShadow: boxShadow.elevation1,
};

export const tabMenuStyle: SxProps = {
  ".MuiTabs-flexContainer": {
    "& .MuiTab-root": {
      marginRight: pxToRem(7),
      backgroundColor: "greyVariantAlt.400",
      color: "greyVariantAlt.700",
      borderRadius: pxToRem(4),
      fontSize: pxToRem(14),
    },
    "& .Mui-selected": {
      backgroundColor: "secondary.A100",
      color: "secondary.A200",
      borderBottom: "0",
    },
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
};

export const checkboxCommonStyle = {
  ".MuiSvgIcon-root": {
    fontSize: pxToRem(24),
    mr: pxToRem(12),
  },
  "&.Mui-checked": {
    ".MuiSvgIcon-root": {
      color: "unset",
    },
  },
  "&:not(.Mui-checked)": {
    ".MuiSvgIcon-root": {
      color: "greyVariantAlt.900",
    },
  },
};

export const dealershipSettingStyle = {
  paperBox: {
    p: `${pxToRem(19)} ${pxToRem(37)} ${pxToRem(31)}`,
  },
};
