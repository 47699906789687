import { UnitSystemEnum } from "appConstants";
import { Asset } from "types/inventory";

export const measurementRegex =
  /(?:(\d+)ft(\d+)in|inch)|(?:(\d+) ft (\d+) in|inch)|(?:(\d+)'[ ]*(\d+)")/;

export const convertMeasurementToMeters = (value: string): string => {
  let totalMeters = 0;

  if (value.includes("ft")) {
    const [feet, inches] = value.split("ft");

    if (inches.includes("inch")) {
      const totalInches =
        parseInt(feet.trim()) * 12 +
        parseInt(inches.replace("inch", "").trim());
      totalMeters = totalInches * 0.0254; // 1 inch = 0.0254 meters
    } else if (inches.includes("in")) {
      const totalInches =
        parseInt(feet.trim()) * 12 + parseInt(inches.replace("in", "").trim());
      totalMeters = totalInches * 0.0254; // 1 inch = 0.0254 meters
    }

    return totalMeters.toFixed(2);
  } else if (value.includes("'")) {
    const [feet, inches] = value.split("'");
    const parsedInches = inches.replace('"', "").trim();
    const totalInches = parseInt(feet) * 12 + parseInt(parsedInches);
    totalMeters = totalInches * 0.0254; // 1 inch = 0.0254 meters

    return totalMeters.toFixed(2);
  }

  return totalMeters.toFixed(2);
};

export const convertMesurementToInches = (value: string): string => {
  let totalInches = 0;
  if (value?.toString().includes("ft")) {
    const [feet, inches] = value.split("ft");

    if (inches.includes("inch")) {
      totalInches =
        parseInt(feet.trim()) * 12 +
        parseInt(inches.replace("inch", "").trim());
    } else if (inches.includes("in")) {
      totalInches =
        parseInt(feet.trim()) * 12 + parseInt(inches.replace("in", "").trim());
    }

    return totalInches.toString();
  } else if (value?.toString().includes("'")) {
    const [feet, inches] = value.split("'");
    const parsedInches = inches.replace('"', "").trim();
    totalInches = parseInt(feet) * 12 + parseInt(parsedInches);

    return totalInches.toString();
  }

  return totalInches.toString();
};

export const convertMetersToMeasurement = (meters: string): string => {
  if (!meters) {
    return meters;
  }
  const totalInches = parseFloat(meters) / 0.0254; // Convert meters to inches
  const feet = Math.floor(totalInches / 12);
  const remainingInches = Math.round(totalInches % 12);

  return `${feet || 0} ft ${remainingInches || 0} in`;
};
export const convertMetersToInches = (meters: string): number => {
  const totalInches = parseFloat(meters) / 0.0254; // Convert meters to inches
  return Math.round(totalInches);
};
export const convertInchesToMeasurement = (inches: string): string => {
  if (!inches) {
    return inches;
  }
  const totalInches = parseFloat(inches);
  const feet = Math.floor(totalInches / 12);
  const remainingInches = Math.round(totalInches % 12);

  return `${feet || 0} ft ${remainingInches || 0} in`;
};

export const convertInchesToMeters = (inches: string): string => {
  if (!inches) {
    return inches;
  }
  const totalMeters = parseFloat(inches) * 0.0254; // Convert inches to meters
  return parseFloat(totalMeters.toFixed(2)).toString(); // Rounding to 2 decimal places
};

export const convertWeightToPounds = (value: string): string => {
  let totalPounds = 0;

  if (parseFloat(value.toString().trim()) > 0) {
    totalPounds = parseFloat(value.toString().trim()) * 2.20462;
    return totalPounds.toString();
  }

  return totalPounds.toString();
};

export const convertPoundsToKg = (value: string): string => {
  let totalKg = 0;

  if (parseFloat(value.toString().trim()) > 0) {
    totalKg = parseFloat(value.toString().trim()) / 2.20462;
    return totalKg.toFixed(2);
  }

  return totalKg.toString();
};

export const convertKgToPounds = (value: string): string => {
  let totalPounds = 0;

  if (parseFloat(value.toString().trim()) > 0) {
    totalPounds = parseFloat(value.toString().trim()) * 2.20462;
    return totalPounds.toFixed(2);
  }

  return totalPounds.toString();
};

export const convertDimensions = (data: Asset) => {
  if (data.unitSystem === UnitSystemEnum.IMPERIAL) {
    // Convert dimensions to measurement
    data.length = convertInchesToMeasurement(data.length);
    data.width = convertInchesToMeasurement(data.width);
    data.height = convertInchesToMeasurement(data.height);
    data.lengthToHitch = convertInchesToMeasurement(data.lengthToHitch);
  } else if (data.unitSystem === UnitSystemEnum.METRIC) {
    // Convert dimensions to meters
    data.length = convertInchesToMeters(data.length);
    data.width = convertInchesToMeters(data.width);
    data.height = convertInchesToMeters(data.height);
    data.lengthToHitch = convertInchesToMeters(data.lengthToHitch);
    data.weight = convertPoundsToKg(data.weight);
    data.grossWeight = convertPoundsToKg(data.grossWeight);
  }
  // Return the manipulated data
  return data;
};
