import { useEffect } from "react";
import { SelectWrapperPropType } from ".";

const useSelectWrapper = (props: SelectWrapperPropType) => {
  const {
    items,
    value,
    itemLabelField = "label",
    itemValueField = "value",
    onValueMapToLabel,
    ...selectProps
  } = props;

  const isSimpleStringArray =
    typeof items?.[0] !== "object" &&
    (typeof items?.[0] === "number" || typeof items?.[0] === "string");

  useEffect(() => {
    mapLabelToValue();
  }, [value, items]);

  const mapLabelToValue = () => {
    if (onValueMapToLabel) {
      const item =
        items?.length &&
        items.find(
          (item) =>
            (isSimpleStringArray ? item : item[itemValueField]) === value
        );
      if (item) {
        onValueMapToLabel(
          selectProps.name,
          isSimpleStringArray ? item : item[itemLabelField]
        );
      }
    }
  };

  return { isSimpleStringArray };
};

export default useSelectWrapper;
