import { FC } from "react";
import {
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
  TimePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { datePickerStyle } from "appConstants/styles";
import {
  convertToDayjsDate,
  convertTimeSpanToDayJS,
  dayjsToTimeSpan,
} from "utils/common";
import { Dayjs } from "dayjs";

type TimePickerWrapperPropType = {
  label?: string;
  value?: any;
  onChange: (value: any) => void;
} & Partial<TimePickerProps<any>>;

const defaultTime = convertToDayjsDate(new Date().toISOString());

const TimePickerWrapper: FC<TimePickerWrapperPropType> = (props) => {
  const {
    label = "HH:MM",
    value = defaultTime,
    onChange,
    slotProps,
    ...otherProps
  } = props;

  const onChangeHandler = (time: Dayjs) => {
    onChange(dayjsToTimeSpan(time));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        slotProps={slotProps}
        label={label}
        value={value ? convertTimeSpanToDayJS(value) : defaultTime}
        onChange={onChangeHandler}
        sx={{ ...datePickerStyle, width: "100%" }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        {...otherProps}
      />
    </LocalizationProvider>
  );
};

export default TimePickerWrapper;
