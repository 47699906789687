import { ReactNode, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  Typography,
} from "@mui/material";
import {
  checkboxCommonStyle,
  inlineTableSelectStyle,
  pxToRem,
  textFiledStyle,
} from "appConstants";
import useSelectWrapper from "./useSelectWrapper";

type ItemsType = {
  label: string | number | ReactNode;
  value: string | number;
};

export type SelectWrapperPropType = {
  label?: string;
  items: any[] | ItemsType[];
  itemLabelField?: string; // INFO: this is used to make it more dynamic so that __
  itemValueField?: string; // __ we can pass any data array and only need to tell the vale and label fields or keys;
  variant?: "filled" | "outlined" | "standard";
  menuItemSx?: SxProps;
  isCheckboxStyle?: boolean;
  inTableRow?: boolean;
  onValueMapToLabel?: (name: string, label: string | number) => void;
} & SelectProps;

const SelectWrapper = (props: SelectWrapperPropType) => {
  const {
    label,
    items,
    itemLabelField = "label",
    itemValueField = "value",
    variant,
    menuItemSx,
    isCheckboxStyle = false,
    value,
    multiple = false,
    sx: selectSx,
    inTableRow,
    onValueMapToLabel,
    ...selectProps
  } = props;

  const { isSimpleStringArray } = useSelectWrapper(props);

  return (
    <FormControl
      fullWidth
      variant={variant ?? "filled"}
      sx={{
        ...textFiledStyle,
        ...(!label && {
          "p.MuiTypography-root.MuiTypography-body1": {
            mt: pxToRem(-5),
          },
        }),
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        {...(multiple && {
          renderValue: (selected) => (selected as string[]).join(", "),
        })}
        multiple={multiple}
        sx={{ ...selectSx, ...(inTableRow && inlineTableSelectStyle) }}
        // displayEmpty
        {...selectProps}
      >
        {items?.length ? (
          items.map((item) => (
            <MenuItem
              key={isSimpleStringArray ? item : item[itemValueField]}
              value={isSimpleStringArray ? item : item[itemValueField]}
              sx={menuItemSx}
            >
              {isCheckboxStyle ? (
                <>
                  <Checkbox
                    checked={(value as any[])?.includes(
                      isSimpleStringArray ? item : item[itemValueField]
                    )}
                    sx={checkboxCommonStyle}
                  />
                  <ListItemText
                    primary={isSimpleStringArray ? item : item[itemLabelField]}
                    sx={{
                      "span.MuiTypography-root": { fontSize: pxToRem(14) },
                    }}
                  />
                </>
              ) : (
                <Typography variant="body1">
                  {isSimpleStringArray ? item : item[itemLabelField]}
                </Typography>
              )}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No Options Available
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectWrapper;
