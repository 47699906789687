import { AlertColor } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetError as resetRoleError } from "redux/slices/role/role.slice";
import {
  resetError as resetPersonnelError,
  resetSuccess as resetPersonnelSuccess,
} from "redux/slices/personnel/personnel.slice";
import {
  resetError as resetInventoryError,
  resetSuccess as resetInventorySuccess,
} from "redux/slices/inventory/inventory.slice";
import { AppDispatch, RootState } from "redux/store";
import { RoleState } from "redux/slices/role/role.model";
import { resetError as resetTimeClockError } from "redux/slices/timeClock/timeClock.slice";

import {
  resetError as resetDispatchError,
  resetSuccess as resetDispatchSuccess,
} from "redux/slices/dispatch/dispatch.slice";
import {
  resetError as resetDealershipError,
  resetSuccess as resetDealershipSuccess,
} from "redux/slices/dealership/dealership.slice";
import {
  resetError as resetPurchaseError,
  resetSuccess as resetPurchaseSuccess,
} from "redux/slices/purchasing/purchasing.slice";
import {
  resetError as resetFinanceError,
  resetSuccess as resetFinanceSuccess,
} from "redux/slices/finance/finance.slice";

import { SUCCESS_MESSAGE_TIMEOUT } from "appConstants/index";

const useAlertWrapper = () => {
  const [error, setError] = useState<RoleState["error"]>({
    title: "",
    description: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>("error");
  const dispatch = useDispatch<AppDispatch>();

  // Read errors and success from every module.
  const { error: rolesError, successMessage: roleSuccessMessage } = useSelector(
    (state: RootState) => state.role
  );
  const {
    error: personnelError,
    isImportSuccess: userBulkImportSuccess,
    successMessage: personnelSuccessMessage,
  } = useSelector((state: RootState) => state.personnel);
  const { error: assetError, successMessage: assetSuccessMessage } =
    useSelector((state: RootState) => state.asset);

  const { error: timeClockError } = useSelector(
    (state: RootState) => state.timeClock
  );

  const { error: dispatchError, successMessage: dispatchSuccessMessage } =
    useSelector((state: RootState) => state.dispatch);

  const { error: dealershipError, successMessage: dealershipSuccessMessage } =
    useSelector((state: RootState) => state.dealership);

  const { error: purchaseError, successMessage: purchaseSuccessMessage } =
    useSelector((state: RootState) => state.purchasing);

  const { error: financeError, successMessage: financeSuccessMessage } =
    useSelector((state: RootState) => state.finance);

  // Check for errors
  useEffect(() => {
    if (rolesError.title) setError(rolesError);
    else if (personnelError.title) setError(personnelError);
    else if (assetError.title) setError(assetError);
    else if (timeClockError.title) setError(timeClockError);
    else if (dispatchError.title) setError(dispatchError);
    else if (dealershipError.title) setError(dealershipError);
    else if (purchaseError.title) setError(purchaseError);
    else if (financeError.title) setError(financeError);
  }, [
    rolesError,
    personnelError,
    assetError,
    timeClockError,
    dispatchError,
    dealershipError,
    purchaseError,
    financeError,
  ]);

  // Check for success
  useEffect(() => {
    if (roleSuccessMessage) setSuccessMessage(roleSuccessMessage);
    else if (userBulkImportSuccess) setSuccessMessage(userBulkImportSuccess);
    else if (personnelSuccessMessage)
      setSuccessMessage(personnelSuccessMessage);
    else if (assetSuccessMessage) setSuccessMessage(assetSuccessMessage);
    else if (dispatchSuccessMessage) setSuccessMessage(dispatchSuccessMessage);
    else if (dealershipSuccessMessage)
      setSuccessMessage(dealershipSuccessMessage);
    else if (purchaseSuccessMessage) setSuccessMessage(purchaseSuccessMessage);
    else if (financeSuccessMessage) setSuccessMessage(financeSuccessMessage);
  }, [
    roleSuccessMessage,
    userBulkImportSuccess,
    personnelSuccessMessage,
    assetSuccessMessage,
    dispatchSuccessMessage,
    dealershipSuccessMessage,
    purchaseSuccessMessage,
    financeSuccessMessage,
  ]);

  //   Reset error fields when alert closed
  useEffect(() => {
    if (!open) {
      if (error.title) {
        if (rolesError.title) dispatch(resetRoleError());
        else if (personnelError.title) dispatch(resetPersonnelError());
        else if (assetError.title) dispatch(resetInventoryError());
        else if (timeClockError.title) dispatch(resetTimeClockError());
        else if (dispatchError.title) dispatch(resetDispatchError());
        else if (dealershipError.title) dispatch(resetDealershipError());
        else if (purchaseError.title) dispatch(resetPurchaseError());
        else if (financeError.title) dispatch(resetFinanceError());
      }

      if (successMessage) {
        if (assetSuccessMessage) dispatch(resetInventorySuccess());
        else if (personnelSuccessMessage) dispatch(resetPersonnelSuccess());
        else if (dispatchSuccessMessage) dispatch(resetDispatchSuccess());
        else if (dealershipSuccessMessage) dispatch(resetDealershipSuccess());
        else if (purchaseSuccessMessage) dispatch(resetPurchaseSuccess());
        else if (financeSuccessMessage) dispatch(resetFinanceSuccess());
        setSuccessMessage("");
      }
    }
  }, [open]);

  useEffect(() => {
    setOpen(!!error.title);
  }, [error]);

  useEffect(() => {
    if (successMessage) {
      setSeverity("success");
      setOpen(true);

      const timer = setTimeout(() => {
        setOpen(false);
      }, 1000 * SUCCESS_MESSAGE_TIMEOUT);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return { open, setOpen, severity, error, successMessage };
};

export default useAlertWrapper;
