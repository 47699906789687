import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import AlertCard from "components/AlertCard";
import { DATE_TIME_FORMAT, TIMECLOCK_MESSAGES } from "appConstants";
import { Attendance } from "types/timeclock";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import TimeClockCardAtom from "components/TimeClockCardAtom";
import { Personnel } from "types/personnel";

dayjs.extend(utc);
dayjs.extend(timezone);

interface OutTimeClockComponentProps {
  checkOutHandler: () => void;
  attendances: Attendance[];
  personnel: Personnel;
}

export const OutTimeClock: React.FC<OutTimeClockComponentProps> = ({
  checkOutHandler,
  attendances,
  personnel,
}) => {
  const [currentDayRecord, setCurrentDayRecord] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [isShiftValid, setIsShiftValid] = useState(null);
  const [showValidationError, setShowValidationError] = useState(false);
  // Get the user's local timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    // NOTE: Commented the logic for checkout shifttime, will be use in future if needed.
    const shiftTime = true; //personnel?.shiftTime;

    if (shiftTime) {
      // const [startTime, endTime] = shiftTime
      //   .replace(/am/g, " AM")
      //   .replace(/pm/g, " PM")
      //   .split(" - ")
      //   .map((time) => dayjs(time, "h A").tz(userTimeZone));

      // const currentTime = dayjs().tz(userTimeZone);
      // setIsShiftValid(currentTime.isBefore(endTime));
      // NOTE: Commented the logic for checkout shifttime, will be use in future if needed.
      setIsShiftValid(true);
    }

    //NOTE: Get the current date in the format "YYYY-MM-DD"
    const currentDate = new Date().toString();
    const formattedDate = dayjs(currentDate)
      .tz(userTimeZone)
      .format(DATE_TIME_FORMAT.date.YYYY_MM_DD_WITH_HYPHENS);

    // Filter the attendances array to find records for the current date
    if (attendances && attendances.length > 0) {
      const currentDayRecords = attendances.filter(
        (record) =>
          record.checkinDateTime &&
          record.checkinDateTime.includes(formattedDate)
      );
      const dndLogsArray =
        currentDayRecords[0] !== undefined ? currentDayRecords[0].dndLogs : 0;

      // NOTE: Use the first matching record as the current day record
      if (currentDayRecords[0] === undefined) {
        setClicked(true);
      }

      // Now, you can map through the dndLogsArray to access individual dndLogs
      else if (
        currentDayRecords[0].checkinDateTime &&
        currentDayRecords[0].checkoutDateTime
      ) {
        setClicked(true);
      } else if (dndLogsArray && dndLogsArray.length > 0) {
        const latestDNDLog = dndLogsArray[dndLogsArray.length - 1];

        if (
          latestDNDLog.startTime &&
          latestDNDLog.endTime &&
          currentDayRecords[0].checkoutDateTime === null
        ) {
          setClicked(false);
        } else if (
          latestDNDLog.startTime &&
          currentDayRecords[0].checkoutDateTime === null
        ) {
          setClicked(true);
        } else setClicked(false);
      } else {
        setClicked(false);
      }

      setCurrentDayRecord(currentDayRecords[0]);
    } else {
      setClicked(true);
    }
  }, [attendances, personnel]);

  const handleButtonClick = () => {
    setShowValidationError(true);

    if (isShiftValid) {
      setClicked(true);
      checkOutHandler();
    }
  };

  return (
    <>
      {showValidationError && !isShiftValid && (
        <AlertCard
          severity="error"
          alertMessage={TIMECLOCK_MESSAGES.shiftTimeEnded}
          openState={true}
        />
      )}
      <TimeClockCardAtom
        type="out"
        value={currentDayRecord?.clockOut}
        isDisabled={clicked}
        handleClick={handleButtonClick}
      />
    </>
  );
};
