import { Avatar, Badge, BadgeProps, IconButton } from "@mui/material";
import {
  avatarLarge,
  avatarMedium,
  avatarSmall,
  avatorBase,
  profileIconBase,
} from "appConstants";
import styled from "@emotion/styled";
import theme from "utils/theme";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { AccountCircle } from "@mui/icons-material";

// proptypes
type Props = {
  src?: string;
  allowUpload?: boolean;
  badgeStatus?: "online" | "dnd" | "offline" | null;
  className?: string;
  sx?: object;
  userName?: string;
  avatarSize?: "large" | "medium" | "small";
  indicator?: React.FC<BadgeProps>;
  onClickUploadHandler?: () => void;
  profileIcon?: boolean;
};

function stringAvatar(name: string) {
  return {
    children: name
      .match(/\b(\w)/g)
      .slice(0, 2)
      .map((char) => char.toUpperCase())
      .join(""),
  };
}

const AvatarWrapper = (props: Props) => {
  const {
    src,
    allowUpload,
    badgeStatus,
    className,
    sx,
    userName,
    avatarSize,
    indicator,
    onClickUploadHandler,
    profileIcon,
  } = props;

  const isLarge = avatarSize === "large";
  const isMedium = avatarSize === "medium";
  const isSmall = avatarSize === "small";
  const themeStyle = theme;

  const indicatorColor =
    props.badgeStatus === "online"
      ? theme.palette.success[500]
      : props.badgeStatus === "dnd"
      ? theme.palette.error[300]
      : theme.palette.grey[800];

  const indicatorSize = isLarge ? "16px" : isSmall ? "10px" : "12px";

  // indicator Style [Start]
  const Indicator =
    indicator ??
    styled(Badge)(({ theme }) => ({
      "& .MuiBadge-badge": {
        backgroundColor: indicatorColor,
        color: indicatorColor,
        boxShadow: `0 0 0 2px ${themeStyle.palette.grey["A400"]}`,
        width: indicatorSize,
        height: indicatorSize,
        borderRadius: "50%",

        "&::after": {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          animation: "ripple 1.2s infinite ease-in-out",
          border: "1px solid currentColor",
          content: '""',
        },
      },
      "@keyframes ripple": {
        "0%": {
          transform: "scale(.8)",
          opacity: 1,
        },
        "100%": {
          transform: "scale(2.4)",
          opacity: 0,
        },
      },
    }));
  // indicator Style [END]

  // avator upload icon style [Start]
  const uploadIcon = {
    backgroundColor: themeStyle.palette.grey[900],
    border: `2px solid ${themeStyle.palette.grey["A400"]}`,
    width: "35px",
    height: "35px",
    transition: "all 0.3s ease-in-out",

    "&:hover": {
      backgroundColor: themeStyle.palette.grey[800],
    },

    //icon
    "& .MuiSvgIcon-root": {
      color: themeStyle.palette.grey["600"],
    },
  };
  // avator upload icon style [Start]

  // Avatar Atom
  const AvatarAtom = () => {
    if (!userName && !src && profileIcon) {
      return (
        <Avatar
          className={className}
          sx={{
            ...(!profileIcon ? avatorBase : profileIconBase),
            ...(isLarge && avatarLarge),
            ...(isSmall && avatarSmall),
            ...(isMedium && avatarMedium),
            ...sx,
          }}
        >
          <AccountCircle />
        </Avatar>
      );
    }
    return (
      <Avatar
        className={className}
        sx={{
          ...(!profileIcon ? avatorBase : profileIconBase),
          ...(isLarge && avatarLarge),
          ...(isSmall && avatarSmall),
          ...(isMedium && avatarMedium),
          ...sx,
        }}
        src={src}
        {...(userName && { ...stringAvatar(userName) })}
      ></Avatar>
    );
  };

  return (
    <>
      {/* simple avator */}
      {!allowUpload && !badgeStatus && <AvatarAtom />}

      {/* Avator with indicator */}
      {!allowUpload && badgeStatus && (
        <Indicator
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="dot"
        >
          <AvatarAtom />
        </Indicator>
      )}

      {/* upload avator */}
      {allowUpload && !badgeStatus && (
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <IconButton
              aria-label="upload avator"
              sx={uploadIcon}
              onClick={onClickUploadHandler}
            >
              <CameraAltIcon fontSize="small" />
            </IconButton>
          }
        >
          <AvatarAtom />
        </Badge>
      )}
    </>
  );
};

export default AvatarWrapper;
