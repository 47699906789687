import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_ADDRESS_TYPE_URL,
  GET_EMAIL_TYPE_URL,
  GET_PHONE_TYPE_URL,
  GET_WEB_TYPE_URL,
  GET_API_TYPE_URL,
  CREATE_DEALERSHIP_URL,
  UPDATE_DEALERSHIP_URL,
  GET_ALL_DEALERSHIP_URL,
  GET_BY_ID_DEALERSHIP_URL,
  DELETE_DEALERSHIP_URL,
  GET_TENANT_LOCATIONS,
} from "utils/urls";
import {
  GetDealershipByIdPayload,
  DeleteDealershipPayload,
  CreateDealershipPayload,
} from "types/dealership";
import { getRequest, postRequest, putRequest } from "utils/axiosClient";

import {
  API_RESPONSE_TYPE,
  dealershipSuccessMessages,
  ERROR_MESSAGES,
  STATUS_CODE,
} from "appConstants";
import AppError from "../../../utils/AppError";

export const getAddressType = createAsyncThunk(
  GET_ADDRESS_TYPE_URL,
  async () => {
    try {
      const response = await getRequest(GET_ADDRESS_TYPE_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAddressType,
        error.response.data?.message
      );
    }
  }
);
export const getPhoneType = createAsyncThunk(GET_PHONE_TYPE_URL, async () => {
  try {
    const response = await getRequest(GET_PHONE_TYPE_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getPhoneType,
      error.response.data?.message
    );
  }
});
export const getEmailType = createAsyncThunk(GET_EMAIL_TYPE_URL, async () => {
  try {
    const response = await getRequest(GET_EMAIL_TYPE_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getEmailType,
      error.response.data?.message
    );
  }
});
export const getWebType = createAsyncThunk(GET_WEB_TYPE_URL, async () => {
  try {
    const response = await getRequest(GET_WEB_TYPE_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getWebType, error.response.data?.message);
  }
});

export const getAPIType = createAsyncThunk(GET_API_TYPE_URL, async () => {
  try {
    const response = await getRequest(GET_API_TYPE_URL);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getAPIType, error.response.data?.message);
  }
});

export const getAllDealerships = createAsyncThunk(
  GET_ALL_DEALERSHIP_URL,
  async () => {
    try {
      const response = await getRequest(GET_ALL_DEALERSHIP_URL);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllDealerships,
        error.response.data?.message
      );
    }
  }
);

export const getDealershipById = createAsyncThunk(
  GET_BY_ID_DEALERSHIP_URL,
  async (payload: GetDealershipByIdPayload) => {
    try {
      // TODO: any type will be replace with appropriate type
      const response = await getRequest<any>(GET_BY_ID_DEALERSHIP_URL, {
        params: payload,
        metadata: { expectedType: API_RESPONSE_TYPE.OBJECT },
      });
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllDealerships,
        error.response.data?.message
      );
    }
  }
);

export const createDealership = createAsyncThunk(
  CREATE_DEALERSHIP_URL,
  async (payload: CreateDealershipPayload | any) => {
    try {
      // TODO: any type will be replace with appropriate type
      const response = await postRequest<any>(CREATE_DEALERSHIP_URL, payload);
      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        if (payload?.isSubgroup) {
          return dealershipSuccessMessages.createSubgroup;
        }
        return dealershipSuccessMessages.createDealership;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createDealership,
        error.response.data?.message
      );
    }
  }
);

export const updateDealership = createAsyncThunk(
  UPDATE_DEALERSHIP_URL,
  async (payload: unknown) => {
    try {
      const response = await putRequest(UPDATE_DEALERSHIP_URL, payload);
      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.updateDealership,
        error.response.data?.message
      );
    }
  }
);

export const deleteDealership = createAsyncThunk(
  DELETE_DEALERSHIP_URL,
  async (payload: DeleteDealershipPayload) => {
    try {
      const response = await postRequest(DELETE_DEALERSHIP_URL, payload);
      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.deleteDealership,
        error.response.data?.message
      );
    }
  }
);

export const getTenantLocations = createAsyncThunk(
  GET_TENANT_LOCATIONS,
  async () => {
    try {
      const response = await getRequest(GET_TENANT_LOCATIONS);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getTenantLocations,
        error.response.data?.message
      );
    }
  }
);
