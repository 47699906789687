import { Box } from "@mui/material";
import { DividerAccordion } from "./../index";
import { pxToRem, TableWrapperStyle } from "appConstants/styles";

interface Props {
  tableFilterWrapper?: React.ReactNode;
  children?: React.ReactNode;
  sx?: object;
  tableFilterWrapperSx?: object;
}

const TableWrapper = (props: Props) => {
  const { children, tableFilterWrapper, sx, tableFilterWrapperSx } = props;

  return (
    <Box sx={{ ...TableWrapperStyle, ...sx }}>
      {tableFilterWrapper && (
        <Box
          sx={{
            backgroundColor: "grey.A400",
            p: pxToRem(13, 20, 0),
            ...tableFilterWrapperSx,
          }}
        >
          <DividerAccordion>{tableFilterWrapper}</DividerAccordion>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default TableWrapper;
