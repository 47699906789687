import {
  Snackbar,
  Typography,
  Stack,
  Paper,
  Grid,
  Badge,
  SlideProps,
  Slide,
} from "@mui/material";
import { boxShadow, pxToRem, themeColorHex } from "appConstants";
import AvatarWrapper from "components/Avatar";
import Button from "components/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "@emotion/styled";

type ConfirmationSnackbarProps = {
  username: string;
  avatarSrc: string;
  open: boolean;
  avatarColor?: string;
  color?: string;
  onUndo: () => void;
  onConfirm: () => void;
  handleClose?: () => void;
};

const SlideTransition = (props: SlideProps) => {
  const easeAnimation = "cubic-bezier(0.68, -0.55, 0.27, 1.55)";
  return (
    <Slide
      {...props}
      direction="right"
      timeout={{ enter: 700, exit: 700 }}
      easing={{
        enter: easeAnimation,
        exit: easeAnimation,
      }}
    />
  );
};

const ConfirmationSnackbar: React.FC<ConfirmationSnackbarProps> = (props) => {
  const {
    username,
    avatarSrc,
    open,
    avatarColor = "supportingColor.500",
    color = "grey.50",
    onUndo,
    onConfirm,
    handleClose,
  } = props;

  const BlinkingBadge = styled(Badge)({
    "& .MuiBadge-badge": {
      backgroundColor: themeColorHex("supportingColorAlt[500]"),
      color: "white",
      animation: `
      colorSwitch 1s ease-in 1000ms,
      colorSwitch 4s ease-out 3150ms infinite`,
      transition: "all 4s ease-out",
      height: pxToRem(6),
      minWidth: pxToRem(6),
      padding: "0",
    },
    "@keyframes colorSwitch": {
      "0%": { backgroundColor: themeColorHex("supportingColorAlt[500]") },
      "50%": { backgroundColor: themeColorHex("success[400]"), opacity: 0.09 },
      "100%": { backgroundColor: themeColorHex("supportingColorAlt[500]") },
    },
  });

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      onClose={handleClose}
      sx={{ width: "100%", maxWidth: 288 }}
      TransitionComponent={SlideTransition}
    >
      <Paper
        elevation={1}
        sx={{ padding: `${pxToRem(13)} ${pxToRem(11)} !important` }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" fontSize={pxToRem(15)}>
              Editing Master Settings
            </Typography>
          </Grid>
          <Grid item xs={2.5} mt={pxToRem(5)}>
            <AvatarWrapper
              src={avatarSrc}
              userName={username}
              avatarSize="medium"
              badgeStatus="online"
              indicator={BlinkingBadge}
              sx={{
                backgroundColor: avatarColor,
                backgroundImage: "none !important",
                boxShadow: boxShadow.elevation1,
                color,
                "& .MuiBadge-badge": {
                  animation: "blink 4s infinite ease-in-out 1s",
                  height: pxToRem(6),
                  minWidth: pxToRem(6),
                },
              }}
            />
          </Grid>
          <Grid item xs={9.5}>
            <Typography variant="body2" lineHeight={pxToRem(15)}>
              You are now editing:
              <br /> Master Settings
            </Typography>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              gap={pxToRem(13)}
              mt={pxToRem(5)}
              pr={pxToRem(25)}
            >
              <Button onClick={onUndo} combinedSx={{ maxHeight: pxToRem(30) }}>
                Undo
              </Button>
              <Button
                onClick={onConfirm}
                combinedSx={{
                  backgroundColor: `${themeColorHex(
                    "secondary[A100]"
                  )} !important`,
                  color: "secondary.main",
                  maxHeight: pxToRem(30),
                  "span.MuiButton-icon": {
                    ml: pxToRem(4),
                  },
                }}
                endIcon={<CheckCircleIcon />}
              >
                Confirm
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Snackbar>
  );
};

export default ConfirmationSnackbar;
