import { DISPLAY_MODE } from "appConstants";

export const assetTemplateFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetTemplates = action.payload?.records;
  state.totalRecordsTemplates = action.payload?.totalRecords;
};

export const assetTemplateRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};
export const assetTemplateByIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetTemplate = action.payload;
};

export const assetTemplateByIdRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};

export const purchaseOrdersFulfilled = (state, action) => {
  state.isLoading = false;
  state.purchaseOrders = action.payload?.records;
  state.totalRecordsPurchasing = action.payload?.totalRecords;
};

export const purchaseOrdersRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};

export const createPurchaseOrderFulfilled = (state, action) => {
  state.createPurchaseOrderIsLoading = false;
  state.createPurchaseOrderIsSuccess = true;
  state.successMessage = action.payload;
};

export const createPurchaseOrderRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.createPurchaseOrderIsLoading = false;
};

export const editPurchaseOrderFulfilled = (state, action) => {
  state.createPurchaseOrderIsLoading = false;
  if (action.meta.arg.mode === DISPLAY_MODE.POPUP)
    state.createPurchaseOrderIsSuccess = true;
  state.successMessage = action.payload;
};

export const editPurchaseOrderRejected = (state, action) => {
  const { message } = action.error;
  state.error.title = message;
  state.error.description = message;
  state.createPurchaseOrderIsLoading = false;
};

export const purchaseOrderByIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.purchaseOrder = action.payload;
};

export const purchaseOrderByIdRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};

export const assetTemplateHistoryByIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetTemplateHistory = action.payload;
};

export const assetTemplateHistoryByIdRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};

export const purchaseOrderHistoryByIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.purchaseOrderHistory = action.payload;
};

export const purchaseOrderHistoryByIdRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};

export const viewPurchaseOrderStatusesFulfilled = (state, action) => {
  state.isLoading = false;
  state.purchaseOrderStatuses = action.payload;
};
