import styled from "@emotion/styled";
import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
} from "@mui/material";
import { pxToRem } from "appConstants";

type SwitchWrapperPropType = {
  label?: string;
  formLabelProps?: FormControlLabelProps;
} & Partial<SwitchProps>;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, checked }) => ({
  width: pxToRem(50),
  height: pxToRem(32),
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transitionDuration: "300ms",
    ...(!checked && { margin: 7 }),
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      margin: 4,
      "& + .MuiSwitch-track": {
        backgroundColor: "secondary.main",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 24,
        height: 24,
        backgroundColor: theme?.["palette"]?.grey["A400"],
      },
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "grey[100]",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme?.["palette"]?.greyVariantAlt[900],
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    border: `${pxToRem(2)} solid`,
    borderColor: theme?.["palette"]?.greyVariantAlt[900],
    backgroundColor: theme?.["palette"]?.greyVariant["A200"],
    opacity: 1,
  },
}));

const SwitchWrapper: React.FC<SwitchWrapperPropType> = (props) => {
  const {
    label = "",
    formLabelProps: { sx: formLabelSx, ...formLabelProp } = {},
    ...rest
  } = props;

  return (
    <FormControlLabel
      control={<IOSSwitch {...rest} />}
      label={label}
      sx={{ ml: 0, ...formLabelSx }}
      {...formLabelProp}
    />
  );
};

export default SwitchWrapper;
