import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocations } from "redux/slices/personnel/personnel.action";
import { AppDispatch, RootState } from "redux/store";
import useText from "utils/hooks/useText";
import { LocationTreeViewPropType } from ".";

// INFO: BFS algorithm to find node by his ID
const bfsSearch = (graph, targetId) => {
  const queue = [...graph];
  while (queue.length > 0) {
    const currNode = queue.shift();
    if (currNode.id === targetId) {
      return currNode;
    }
    if (currNode.children) {
      queue.push(...currNode.children);
    }
  }
  return []; // INFO: Target node not found
};

const useLocationTreeview = (props: LocationTreeViewPropType) => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedLocationNodeId = props.selectedLocationNodeId;

  const [locationsData, setLocationsData] = useState([]);
  const [nodeIdFinal, setNodeIdFinal] = useState(selectedLocationNodeId);
  const [selectedNames, setSelectedNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSelectedValue, setShowSelectedValue] = useState(true);

  const { locations } = useSelector((state: RootState) => state.personnel);

  const { locationLabel } = useText("createPersonnelForm");

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  useEffect(() => {
    setLocationsData(locations);
    if (!!selectedLocationNodeId && locations.length > 0) {
      handleNodeSelect(selectedLocationNodeId);
    }
  }, [locations]);

  const searchFirstTierChildren = (nodes: any[], searchTerm: string) => {
    const searchTermLower = searchTerm.toLowerCase();

    // INFO: Filter out top-level nodes that have children matching the search term
    return nodes
      .map((node) => {
        if (node.children?.length || searchTermLower) {
          const matchingChildren = node.children.filter((child) =>
            child.tenantFullName.toLowerCase().includes(searchTermLower)
          );
          if (matchingChildren.length > 0) {
            // INFO: Return the parent node with only matching children
            return {
              ...node,
              children: matchingChildren,
            };
          }
          return null;
        } else {
          return node;
        }
      })
      .filter((node) => node !== null);
  };

  // INFO: Get all parent IDs from specific node
  const getAllParents = useCallback(
    (id, list = []) => {
      const node = bfsSearch(locationsData, id);
      if (node.parentId) {
        list.unshift(node.parentId);
        return getAllParents(node.parentId, list);
      }
      return list;
    },
    [locationsData]
  );

  const handleNodeSelectWithEvent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    nodeId: number
  ) => {
    event.stopPropagation();
    handleNodeSelect(nodeId);
  };

  const findNamesByIds = useCallback(
    (ids: number[], nodes: any[]) => {
      const names = [];
      const findNameById = (id, nodes) => {
        for (const node of nodes) {
          if (node.id === id) {
            names.push(node.tenantName);
            break;
          }
          if (node.children) {
            findNameById(id, node.children);
          }
        }
      };
      ids.forEach((id) => findNameById(id, nodes));
      return names;
    },
    [locationsData, nodeIdFinal, selectedLocationNodeId]
  );

  const handleNodeSelect = useCallback(
    (nodeId: number) => {
      setNodeIdFinal((prevNodeId: number) => {
        // INFO: To unselect already  selected node
        if (prevNodeId === nodeId) {
          setSelectedNames([]);
          return null;
        }

        setSelectedNames(
          findNamesByIds([...getAllParents(nodeId), nodeId], locationsData)
        );
        props.sendLocation(nodeId);
        return nodeId;
      });
    },
    [locationsData, nodeIdFinal]
  );

  const handleExpandClick = (event) => {
    // INFO: prevent the click event from propagating to the checkbox
    event.stopPropagation();
  };

  const handleInputFocus = (show: boolean) => {
    if (!show) {
      setSearchTerm("");
    }
    setShowSelectedValue(show);
  };

  return {
    locationLabel,
    nodeIdFinal,
    selectedNames,
    locationsData,
    searchTerm,
    showSelectedValue,
    handleInputFocus,
    searchFirstTierChildren,
    setSearchTerm,
    handleExpandClick,
    handleNodeSelectWithEvent,
  };
};

export default useLocationTreeview;
