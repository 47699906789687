import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { basicAccordance, pxToRem } from "appConstants/styles";
import useSummaryAccordian from "./useSummaryAccordian";

type SummaryAccordionPropType = {
  expanded?: boolean;
  title: string;
  items: Array<string>;
  subTitle?: string;
};

const SummaryAccordion: React.FC<SummaryAccordionPropType> = (props) => {
  const { expanded, items, title, subTitle } = props;
  const { expand, setExpand } = useSummaryAccordian(expanded);

  return (
    <Accordion
      expanded={expand}
      sx={{ ...basicAccordance.summaryAccordionStyle }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => setExpand(!expand)}
      >
        <Stack direction="row" alignItems={"center"} width={"100%"}>
          <Typography
            variant="body1"
            fontWeight={600}
            color={"grey.50"}
            flex={1}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              variant="body2"
              fontWeight={400}
              color={"grey.50"}
              sx={{ textAlign: "end", mr: pxToRem(13) }}
              flex={0.5}
            >
              {subTitle}
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <ul>
          {items.map((item) => (
            <li key={item}>
              <Typography variant="caption" fontWeight={500}>
                {item}
              </Typography>
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
};

export default SummaryAccordion;
