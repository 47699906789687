import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_URL } from "./urls";
import {
  API_RESPONSE_TYPE,
  LOCAL_STORAGE_KEYS,
  STATUS_CODE,
} from "appConstants";
import AppError from "./AppError";

// INFO: Extend AxiosRequestConfig locally for this wrapper
export type CustomAxiosRequestConfig = AxiosRequestConfig & {
  metadata?: { expectedType: API_RESPONSE_TYPE };
};

const axiosClient = axios.create({
  baseURL: BASE_URL,
});

axiosClient.interceptors.request.use(async (config) => {
  const token: string = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
  // add token in headers if token is present
  if (token && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    //if response header contains a custom field
    if (response.headers["x-custom-field"]) {
      //do something with the custom field value
    }

    // INFO: Check if response data is undefined/null and method is GET
    if (!response?.data && response.config?.method?.toLowerCase() === "get") {
      const { expectedType } = response.config?.["metadata"] || {};

      response.data = expectedType === API_RESPONSE_TYPE.OBJECT ? {} : [];
    }

    return response;
  },

  function (error) {
    // INFO: If error response status is 401-unauthorized (athentication issue)
    if (error.response.status === 401) {
      window.location.href = "/logout";
    }

    // INFO: If a user is not autherized to access a specific route
    if (error.response.status === STATUS_CODE.FORBIDDEN) {
      error.response.data = { message: "Access Denied" };
    }

    return Promise.reject(error);
  }
);

// Custom GET request (To maintain all methods at one location)
export const getRequest = <T>(
  url: string,
  config?: CustomAxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return axiosClient.get(url, config);
};

//INFO: Custom POST request
export const postRequest = <T>(
  url: string,
  data: any,
  config?: CustomAxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return axiosClient.post<T>(url, data, config);
};

//INFO: Custom PUT request
export const putRequest = <T>(
  url: string,
  data?: any,
  config?: CustomAxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return axiosClient.put<T>(url, data, config);
};

//INFO: Custom DELETE request
export const deleteRequest = <T>(
  url: string,
  config?: CustomAxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return axiosClient.delete<T>(url, config);
};

export default axiosClient;
