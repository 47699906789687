export enum DEALERSHIP_SETTINGS_TAB_ID {
  DEPARTMENTS_PAYMENT_TYPES = "departmentsPaymentTypes",
  HOURS_HOLIDAYS = "hoursHolidays",
  CONTACT_INFO = "contactInfo",
  LOT_INFO = "lotInfo",
  CHART_OF_ACCOUNTS = "chartOfAccounts",
  SALE_FINANCE = "saleFinance",
  ASSETS_INVENTORY = "assetsInventory",
  PART_SERVICE = "partService",
  VENDORS = "vendors",
  BRANDS = "brands",
  BANKS = "banks",
  TRANSPORTERS = "transporters",
  WARRANTY_CO = "warrantyCo",
  FINANCE_CO = "financeCo",
  ACCESSORIES = "accessories",
  FINANCE_PRODUCTS = "financeProducts",
  FEES = "fees",
}
