import { createSlice } from "@reduxjs/toolkit";
import { FinanceState } from "./finance.model";
import {
  createQuote,
  getBuyers,
  getLoanTypes,
  getQuoteById,
  getQuotes,
  getFinanceStatuses,
  takeDeposit,
  editQuote,
  getPaymentTypes,
  voidQuote,
} from "./finance.action";
import {
  createQuoteFulfilled,
  createQuoteRejected,
  getBuyersFullfilled,
  getBuyersRejected,
  getLoanTypesFullfilled,
  getLoanTypesRejected,
  getQuoteByIdFulfilled,
  getQuoteByIdRejected,
  getQuotesFulfilled,
  getQuotesRejected,
  getFinanceStatusesFullfilled,
  getFinanceStatusesRejected,
  takeDepositFulfilled,
  takeDepositRejected,
  editQuoteFulfilled,
  editQuoteRejected,
  getPaymentTypesFullfilled,
  getPaymentTypesRejected,
  voidQuoteFulfilled,
  voidQuoteRejected,
} from "./finance.reducer";
import { setReduxCommonPendingState } from "utils/common";

const initialState: FinanceState = {
  isLoading: false,
  successMessage: "",
  error: { title: "", description: "" },
  isSuccess: false,
  depositSuccess: false,
  voidSuccess: false,
  quotes: [],
  totalRecords: 0,
  quote: null,
  buyers: [],
  financeStatuses: [],
  loanTypes: [],
  paymentTypes: [],
};

export const financeSlice = createSlice({
  name: "finance",
  initialState: initialState,
  reducers: {
    resetSuccess: (state) => {
      state.successMessage = "";
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(getQuotes.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getQuotes.fulfilled, (state, action) => {
      getQuotesFulfilled(state, action);
    }),
    builder.addCase(getQuotes.rejected, (state, action) => {
      getQuotesRejected(state, action);
    }),
    builder.addCase(getQuoteById.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getQuoteById.fulfilled, (state, action) => {
      getQuoteByIdFulfilled(state, action);
    }),
    builder.addCase(getQuoteById.rejected, (state, action) => {
      getQuoteByIdRejected(state, action);
    }),
    builder.addCase(createQuote.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(createQuote.fulfilled, (state, action) => {
      createQuoteFulfilled(state, action);
    }),
    builder.addCase(createQuote.rejected, (state, action) => {
      createQuoteRejected(state, action);
    }),
    builder.addCase(editQuote.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(editQuote.fulfilled, (state, action) => {
      editQuoteFulfilled(state, action);
    }),
    builder.addCase(editQuote.rejected, (state, action) => {
      editQuoteRejected(state, action);
    }),
    builder.addCase(voidQuote.pending, (state) => {
      setReduxCommonPendingState(state);
      state.isLoading = false;
    }),
    builder.addCase(voidQuote.fulfilled, (state, action) => {
      voidQuoteFulfilled(state, action);
    }),
    builder.addCase(voidQuote.rejected, (state, action) => {
      voidQuoteRejected(state, action);
    }),
    builder.addCase(getBuyers.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getBuyers.fulfilled, (state, action) => {
      getBuyersFullfilled(state, action);
    }),
    builder.addCase(getBuyers.rejected, (state, action) => {
      getBuyersRejected(state, action);
    }),
    builder.addCase(getFinanceStatuses.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getFinanceStatuses.fulfilled, (state, action) => {
      getFinanceStatusesFullfilled(state, action);
    }),
    builder.addCase(getFinanceStatuses.rejected, (state, action) => {
      getFinanceStatusesRejected(state, action);
    }),
    builder.addCase(getLoanTypes.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getLoanTypes.fulfilled, (state, action) => {
      getLoanTypesFullfilled(state, action);
    }),
    builder.addCase(getLoanTypes.rejected, (state, action) => {
      getLoanTypesRejected(state, action);
    }),
    builder.addCase(getPaymentTypes.pending, (state) => {
      setReduxCommonPendingState(state);
      state.isLoading = false;
    }),
    builder.addCase(getPaymentTypes.fulfilled, (state, action) => {
      getPaymentTypesFullfilled(state, action);
    }),
    builder.addCase(getPaymentTypes.rejected, (state, action) => {
      getPaymentTypesRejected(state, action);
    }),
    builder.addCase(takeDeposit.pending, (state) => {
      setReduxCommonPendingState(state);
      state.isLoading = false;
    }),
    builder.addCase(takeDeposit.fulfilled, (state, action) => {
      takeDepositFulfilled(state, action);
    }),
    builder.addCase(takeDeposit.rejected, (state, action) => {
      takeDepositRejected(state, action);
    })
  ),
});

export const { resetSuccess, resetError, reset } = financeSlice.actions;
