const FORM_FIELDS = {
  ASSET_ID: "AssetId",
  // Unit info and pricing
  STOCK_NUMBER: "StockNumber",
  VIN: "VIN",
  PROFIT_CENTER_ID: "ProfitCenterId",
  UNIT_TYPE_ID: "UnitTypeId",
  ASSET_TYPE_ID: "AssetTypeId",
  ACCOUNT_TYPE_ID: "AssetTypeId",
  YEAR: "UnitYear",
  PURCHASE_DATE: "PurchaseDate",
  PURCHASE_STATUS: "purchaseOrderStatusId",
  ASSET_NAME: "AssetName",
  UNIT_MODEL_ID: "UnitModelId",
  FLOOR_LAYOUT_ID: "FloorLayoutId",
  SUB_FLOOR_PLAN_ID: "SubFloorPlanId",
  BODY_STYLE_ID: "BodyStyleId",
  ASSET_DATA_LINK: "AssetDataLink",
  ASSET_CONDITION: "AssetCondition",
  INTERIOR_COLOR: "InteriorColor",
  INTERIOR_COLOR_2: "InteriorColor2",
  EXTERIOR_COLOR: "ExteriorColor",
  EXTERIOR_COLOR_2: "ExteriorColor2",
  WEIGHT: "Weight",
  GROSS_WEIGHT: "GrossWeight",
  IMAGE_FILES: "ImageFiles",
  ATTACHMENT_FILES: "AttachmentFiles",
  WIDTH: "Width",
  HEIGHT: "Height",
  LENGTH: "Length",
  FUEL_CAPACITY: "FuelCapacity",
  ODOMETER: "Odometer",
  BEDS: "Beds",
  BATHROOMS: "Bathrooms",
  SLIDE_OUTS: "SlideOuts",
  LENGTH_TO_HITCH: "LengthToHitch",
  TOW_CAPACITY: "TowCapacity",
  UNIT_STATUS: "UnitStatusId",
  UNIT_LOCATION: "UnitLocation",
  TITLE_STATUS: "TitleStatusId",
  UNIT_AGE: "UnitAge",
  MANUFACTURER: "UnitManufacturerId",
  DIVISION: "UnitDivisionId",
  UNIT_BRAND: "UnitBrandId",
  SERIES: "UnitSeriesId",
  UNIT_SYSTEM: "UnitSystem",
  TRIM_COLOR: "TrimColor",
  SUB_SERIES: "UnitSubSeriesId",
  SAVE_AS_TEMPLATE: "isTemplate",
  // WARRANTY
  WARRANTY_INFO: "WarrantyInfo",
  WARRANTY_CLAIM: "WarrantyClaimAmount",
  WARRANTY_STATUS: "WarrantyStatusId",
  WARRANTY_TERM: "WarrantyTerm",
  WARRANTY_MILES: "WarrantyMiles",
  // SERVICE INFO
  SERVICE_TYPE: "ServiceType",
  SERVICE_STATUS: "ServiceStatus",
  SERVICE_DATE: "ServiceDate",
  SERVICE_AMOUNT: "ServiceAmount",
  SERVICE_CODE: "ServiceCode",
  SERVICE_DOCUMENT_NUMBER: "ServiceDocumentNumber",
  SERVICE_DEPARTMENT: "ServiceDepartment",
  TEMPORARY_TAG_NUMBER: "TemporaryTagNumber",
  // FUNDING INFO
  FUNDING_INFO: "FundingInfo",
  FLOOR_PLAN_BALANCE: "FloorplanBalance",
  FUNDING_INFO_FLOOR_PLAN_STATUS: "FloorplanStatusId",
  FUNDING_INFO_FUNDED_AMOUNT: "FundedAmount",
  FUNDED_DATE: "FundedDate",
  FUNDING_INFO_DEPOSIT_AMOUNT: "DepositAmount",
  FUNDING_INFO_DEAL_NUMBER: "DealNumber",
  PAID_DATE: "PaidDate",
  DUE_DATE: "DueDate",
  OFFLINE_DATE: "OfflineDate",
  // MAP INFO
  MAP_INFO: "MapInfo",
  MAP_INFO_MAP_PRICING: "MapPricing",
  IS_CONVERSION_UNIT: "IsConversionUnit",
  FREIGHT_COST: "FreightCost",
  DIVISION_ALLOCATION: "DivisionAllocationId",
  COMPARABLE_UNIT: "ComparableUnitId",
  // TITLING INFO
  TITLING_INFO: "TitlingInfo",
  TITLED_IN_HOUSE: "TitleInHouse",
  TITLED: "Titled",
  PRODUCT_GM: "ProductGeneralManagerId",
  BILLING_MANUFACTURER_CO: "BillingManufacturerCompanyId",
  TITLING_BODY: "TitlingBodyStyleId",
  FR_DIV_CODE: "FrDivisionCode",
  HT_QTY: "HtQty",
  DOC_AUDIT: "DocAudit",
  // Admin
  ADMIN_INFO: "AdminInfo",
  ADMIN_PURCHASED_FROM: "purchaseFromVendorId",
  SOLD_TO: "SoldTo",
  SOLD_BY: "SoldBy",
  NAME_ON_TITLE: "NameOnTitle",
  LINE_PAYOFF_NUMBER: "LienPayoffNumber",
  ADMIN_DMV_FEES: "DmvFees",
  ADMIN_TAG_AND_TITLE: "TagAndTitle",
  TITLE_STATE: "TitleState",
  BOOK_VALUE_LOW: "BookValueLow",
  BOOK_VALUE_HIGH: "BookValueHigh",
  ADMIN_SPIFF: "Spiff",
  IS_CONSIGNMENT: "IsConsignment",
  MSO_TITLE_BRAND: "MsoTitleBrand",
  MEMO: "Memo",
  // Accessories
  ACCESSORIES_INFO: "AssetAccessories",
  CODE: "Code",
  DESCRIPTION: "Description",
  PRICE: "Price",
  COST: "Cost",
  ACCESSORY_ID: "AccessoryId",
  IS_COMPLETED: "IsCompleted",
  IS_FACTORY_INSTALLED: "IsFactoryInstalled",
  // Marine
  // Tow Vehicle Info
  TOW_VEHICLE_INFO: "TowVehicleInfo",
  TOW_VEHICLE_ENGINE: "TowVehicleEngine",
  TOW_VEHICLE_MAKE: "TowVehicleMake",
  TOW_VEHICLE_MODEL: "TowVehicleModel",
  TOW_VEHICLE_NO_OF_TIRES: "TowVehicleNoOfTires",
  TOW_VEHICLE_YEAR: "TowVehicleYear",
  // Trailer Info
  TRAILER_INFO: "TrailerInfo",
  TRAILER_ASSET_ID: "TrailerAssetId",
  TRAILER_STOCK_NUMBER: "TrailerStockNumber",
  TRAILER_MODEL: "TrailerModel",
  TRAILER_MAKE: "TrailerMake",
  TRAILER_YEAR: "TrailerYear",
  TRAILER_SERIAL_NUMBER: "TrailerSerialNumber",
  // General Info
  MARINE_GENERAL_INFO: "MarineGeneralInfo",
  SHIP_NAME: "ShipName",
  PROPULTION: "Population",
  CYLINDER: "CylinderTypeId",
  FUEL_TYPE: "FuelTypeId",
  TRANSMISSION: "TransmissionId",
  GENERAL_INFO_FUEL_CAPACITY: "GeneralInfoFuelCapacity",
  HULL_CONST: "HullConstruction",
  GENERAL_INFO_WEIGHT: "GeneralInfoWeight",
  GENERAL_INFO_GVW: "GeneralInfoGvw",
  GENERAL_INFO_LENGTH: "GeneralInfoLength",
  GENERAL_INFO_BEAM: "GeneralInfoBeam",
  OFFICIAL_NUMBER: "OfficialNumber",
  PORT_LOCATION: "PortLocation",
  KEY_NUMBER: "KeyNumber",
  GENERAL_INFO_BEDS: "GeneralInfoBeds",
  // Motor Info
  MOTOR_INFO: "MotorInfo",
  MOTOR_ASSET_ID: "MotorAssetId",
  MOTOR_STOCK_NUMBER: "MotorStockNumber",
  MOTOR_YEAR: "MotorYear",
  MOTOR_MAKE: "MotorMake",
  MOTOR_MODEL: "MotorModel",
  MOTOR_SERIAL_NUMBER: "MotorSerialNumber",
  MOTOR_HP: "MotorHp",
  MOTOR_TRANSFORM: "MotorTransform",
  MOTOR_OVERDRIVE: "MotorOverdrive",

  //other pricing fields
  PRICE_DETAILS: "PriceDetails",
  MSRP: "Selling_Msrp",
  MSRP_MARKUP: "Selling_Markup",
  SELLING_PRICE: "Selling_Price",
  PRICE_CATEGORY: "Selling_PriceCategoryId",
  SELLING_COST_LINE: "Cost_CostLine", //"Selling_Costline",
  DEAL_PRICE: "Selling_DealPrice",
  INTERNAL_PRICE: "Internet_InternalPrice",
  SPECIAL_PRICE: "Internet_SpecialPrice",
  SALE_PRICE: "Internet_SalePrice",
  MAP_PRICING: "Internet_MapPricing",
  ADVERTISING_AMOUNT_LOW: "Internet_AdvertisingAmountLow",
  ADVERTISING_AMOUNT_HIGH: "Internet_AdvertisingAmountHigh",
  IS_DISPLAY_ONLINE: "Internet_IsDisplayOnline",
  PDI_ESTIMATE: "PdI_Estimates",
  PDI_AMOUNT: "PdI_Amount",
  IS_PDI_COMPLETE: "PdI_Complete",
  PURCHASE_PRICE: "Cost_PurchasePrice",
  ADDONS_REPAIRS: "Cost_Addons_Repair",
  DESTINATION_CHARGES: "Cost_DestinationCharges",
  ADDITIONAL_COST: "Cost_AdditionalCosts",
  TOTAL_COST: "Cost_TotalCost",
  COST_LINE: "Cost_CostLine",
  FLOOR_PLAN_INTEREST_COST: "Cost_FloorPlanInterest",
  DEPRECIATION: "Cost_Depreciation",
  ADJUST_AMOUNT: "PaC_AdjustAmount",
  PAC_COST: "PaC_Cost",
  FLOOR_PLAN_LOAN_DATE: "FloorPlan_LoanDate",
  FLOOR_PLAN_STATUS: "FloorPlan_StatusId",
  FLOOR_PLAN_COMPANY: "FloorPlan_CompanyId",
  FLOOR_GL_ACCOUNT: "FloorPlan_GLAccount",
  CURRENT_FLOOR_PLAN_BALANCE: "FloorPlan_CurrentBalance",
  FLOOR_PLAN_INTEREST_FP: "FloorPlan_Interest",
  CURTAILMENTS: "FloorPlan_Curtailments",
  FUNDED_AMOUNT: "FloorPlan_FundedAmount",
  CONFIRMATION_NUMBER: "Reference_ConfirmationNo",
  INVOICE_NUMBER: "Reference_InvoiceNo",
  PO_NUMBER: "Reference_PurchaseOrderId",
  PURCHASE_FROM: "Reference_VendorId",
  MFG_INVOICE_NUMBER: "Reference_ManufacturerInvoiceNo",
  SPIFF: "Reference_Spiff",
  DMV_FEES: "Reference_DMVfees",
  TAG_AND_TITLE: "Reference_TagTitle",
  OPTION_WARRANTY: "Reference_OptionWarranty",
  CUSTOMER_NUMBER: "Customer_Number",
  DEAL_NUMBER: "Customer_DealNumber",
  DEPOSIT_AMOUNT: "Customer_DepositAmount",
  SELLING_STOCK_NUMBER: "Customer_SellingStockNumber",
  DEPOSIT_DATE: "Customer_DepositDate",
  TRADE_STOCK_NUMBER: "Customer_TradeStockNumber",
  DELIVERY_DATE: "Customer_DeliveryDate",
  OVER_ALLOWANCE: "Customer_OverAllowance",
  SOLD_DATE: "Customer_SoldDate",
  PACKAGE_PRICE: "PackagePrice",

  // Location
  DEALERSHIP_NAME: "DealershipId",
  LOCATION: "InitialLocationId",
  LOT_LOCATION: "LotLocationId",
  CURRENT_BUILDING: "LotId",

  // Metadata and Description
  KEYWORDS: "Keywords",
  ALTERNATE_ID: "AlternateId",
  META_DESCRIPTION: "MetaDescription",
  SHORT_DESCRIPTION: "ShortDescription",
  GENERATIVE_PROMPT: "GenerativePrompt",
  DETAIL_DESCRIPTION: "DetailDescription",
  ASSET_DESCRIPTION: "AssetDescription",

  //Account
  STATUS: "Status",
  INITIAL_LOCATION: "initialLocation",
  CURRENT_LOCATION: "currentLocation",
  ON_ORDER: "onOrder",
  SOLD: "sold",
  ON_HOLD: "onHold",
  ESTD_ARRIVAL_DATE: "estdArrivalDate",
  IN_SERVICE_DATE: "inServiceDate",
  HOLD_DATE: "holdDate",
  HOLD_SALESPERSON: "holdSalesperson",
  REFERENCE_NUMBER: "referenceNumber",
  PURCHASED_FROM: "purchasedFrom",
  FROM_NUMBER: "fromNumber",
  ORDER_DATE: "orderDate",
  LIEN_PAYOFF_NUMBER: "lienPayoffNumber",
  ADVERTISING_AMOUNT: "advertisingAmount",
  EXPENSE: "expense",
  SPIFF_AMOUNT: "spiffAmount",

  //Additional Fields
  // MSRP: "msrp",
  // MSRP_MARKUP: "msrpMarkup",
  // SELLING_PRICE: "sellingPrice",
  COST_PERCENT_MARKUP: "costPercentMarkup",

  FLOORPLAN_COMPANY: "floorplanCompany",
  FLOORPLAN_BALANCE: "floorplanBalance",
  FLOORPLAN_ACCOUNT: "floorplanAccount",
  // CURTAILMENTS: "curtailments",
  FLOORPLAN_INTEREST: "floorplanInterest",
  INTEREST_FREE_UNTIL: "interestFreeUntil",

  // PURCHASE_PRICE: "purchasePrice",
  ADD_ONS_AND_REPAIRS: "addOnsAndRepairs",
  ADDITIONAL_COSTS: "additionalCosts",
  // TOTAL_COST: "totalCost",

  DEALER_HOLDBACK: "dealerHoldback",
  // DESTINATION_CHARGES: "destinationCharges",
  // DEPRECIATION: "depreciation",
  // DMV_FEES: "dmvFees",
  // TAG_AND_TITLE: "tagAndTitle",

  // PDI_AMOUNT: "pdiAmount",
  // PDI_ESTIMATE: "pdiEstimate",

  PAC_ADJUST: "pacAdjust",
  PAC_PERCENT: "pacPercent",
  PAC_AMOUNT_PERCENT: "pacAmountPercent",
  // PAC_COST: "pacCost",
  // COST_LINE: "costLine",

  SALES_ACCOUNT: "salesAccount",
  INVENTORY_ACCOUNT: "inventoryAccount",
  COST_ACCOUNT: "costAccount",
  FLOORPLAN_INTEREST_ACCOUNT: "floorplanInterestAccount",
  ADDITIONAL_COST_ACCOUNT: "additionalCostAccount",
  ADDITIONAL_COST_ENTRY_REFERENCE: "additionalCostEntryReference",

  PACKAGE_COST: "packageCost",
  PACKAGE_PAC_COST: "packagePacCost",
} as const;

export default FORM_FIELDS;
