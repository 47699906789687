import { quoteSuccessMessages } from "appConstants";
import { FinanceState } from "./finance.model";

export const getQuotesFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.quotes = action.payload.records;
  state.totalRecords = action.payload?.totalRecords;
};

export const getQuotesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const getQuoteByIdFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.quote = action.payload;
};

export const getQuoteByIdRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const createQuoteFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = quoteSuccessMessages.quoteCreated;
};

export const createQuoteRejected = (state: FinanceState, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const editQuoteFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = quoteSuccessMessages.quoteUpdated;
};

export const editQuoteRejected = (state: FinanceState, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const voidQuoteFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.voidSuccess = true;
  state.successMessage = quoteSuccessMessages.quoteVoided;
};

export const voidQuoteRejected = (state: FinanceState, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getBuyersFullfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.buyers = action.payload?.records;
  state.totalRecords = action.payload?.totalRecords;
};

export const getBuyersRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const getFinanceStatusesFullfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.financeStatuses = action.payload;
};

export const getFinanceStatusesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const getLoanTypesFullfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.loanTypes = action.payload;
};

export const getLoanTypesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

export const getPaymentTypesFullfilled = (state: FinanceState, action) => {
  state.paymentTypes = action.payload;
};

export const getPaymentTypesRejected = (state: FinanceState, action) => {
  state.error = action.error.message;
};

export const takeDepositFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.depositSuccess = true;
  state.successMessage = quoteSuccessMessages.takeDeposit;
};

export const takeDepositRejected = (state: FinanceState, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};
