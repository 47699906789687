import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import AlertCard from "components/AlertCard";
import { DATE_TIME_FORMAT, TIMECLOCK_MESSAGES } from "appConstants";
import { Attendance } from "types/timeclock";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import TimeClockCardAtom from "components/TimeClockCardAtom";
import { Personnel } from "types/personnel";

dayjs.extend(utc);
dayjs.extend(timezone);

interface InTimeClockComponentProps {
  checkInHandler: () => void;
  attendances: Attendance[];
  personnel: Personnel;
}

export const InTimeClock: React.FC<InTimeClockComponentProps> = ({
  checkInHandler,
  attendances,
  personnel,
}) => {
  const [currentDayRecord, setCurrentDayRecord] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [isShiftValid, setIsShiftValid] = useState(null);
  const [showValidationError, setShowValidationError] = useState(false);
  // Get the user's local timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    const shiftTime = personnel?.shiftTime;

    if (shiftTime !== undefined && shiftTime !== null) {
      const [originalStartTime, endTime] = shiftTime
        .replace(/am/g, " AM")
        .replace(/pm/g, " PM")
        .split(" - ")
        .map((time) => dayjs(time, "h A").tz(userTimeZone));

      // Calculate a new start time of 7:55 AM
      const newStartTime = originalStartTime.clone().subtract(5, "minutes");

      const currentTime = dayjs().tz(userTimeZone);
      const shiftTimeIsValid =
        currentTime.isAfter(originalStartTime) ||
        currentTime.isAfter(newStartTime);
      setIsShiftValid(shiftTimeIsValid);
    } else {
      setIsShiftValid(true);
    }
    //NOTE: Get the current date in the format "YYYY-MM-DD"
    const currentDate = new Date().toString();
    const formattedDate = dayjs(currentDate)
      .tz(userTimeZone)
      .format(DATE_TIME_FORMAT.date.YYYY_MM_DD_WITH_HYPHENS);
    //NOTE: Filter the attendances array to find records for the current date
    if (attendances && attendances.length > 0) {
      const currentDayRecords = attendances.filter(
        (record) =>
          record.checkinDateTime &&
          record.checkinDateTime.includes(formattedDate)
      );

      //NOTE: Use the first matching record as the current day record
      if (currentDayRecords[0] != null) {
        setClicked(true);
      }
      setCurrentDayRecord(currentDayRecords[0]);
    }
  }, [attendances, personnel?.shiftTime]);

  const handleButtonClick = () => {
    if (isShiftValid) {
      setClicked(true);
      checkInHandler();
    } else {
      setShowValidationError(true);
    }
  };

  return (
    <>
      {showValidationError && (
        <AlertCard
          severity="error"
          alertMessage={TIMECLOCK_MESSAGES.shiftTimeNotStarted}
          openState={true}
          isFloating
        />
      )}

      <TimeClockCardAtom
        type="in"
        value={currentDayRecord?.clockIn}
        isDisabled={clicked}
        handleClick={handleButtonClick}
      />
    </>
  );
};
