import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DEAL_URL,
  GET_BUYERS,
  GET_LOAN_TYPES_URL,
  GET_PAYMENT_TYPES_URL,
  GET_QUOTES_URL,
  GET_QUOTE_STATUSES_URL,
  VOID_QUOTE_URL,
} from "utils/urls";
import { getRequest, postRequest, putRequest } from "utils/axiosClient";
import { ERROR_MESSAGES, JSON_HEADER, STATUS_CODE } from "appConstants";
import AppError from "utils/AppError";
import {
  GetBuyersPayload,
  GetQuoteByIDPayload,
  GetQuotesPayload,
  TakeDeposit,
} from "types/finance";

const typePrefix = {
  GET_QUOTES: "getQuotes",
  GET_QUOTE_BY_ID: "getQuoteById",
  CREATE: "createQuote",
  EDIT: "editQuote",
  VOID: "voidQuote",
  TAKE_DEPOSIT: "takeDeposit",
};

export const getQuotes = createAsyncThunk(
  typePrefix.GET_QUOTES,
  async (payload: GetQuotesPayload) => {
    try {
      const response = await getRequest<any>(GET_QUOTES_URL, {
        params: payload,
      });

      return {
        records: response.data?.records,
        totalRecords: response.data?.totalRecords,
      };
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getQuotes, error.response.data?.title);
    }
  }
);

export const getQuoteById = createAsyncThunk(
  typePrefix.GET_QUOTE_BY_ID,
  async (payload: GetQuoteByIDPayload) => {
    try {
      const response = await getRequest<any>(GET_QUOTES_URL + "/" + payload.id);
      return response.data;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getQuotes, error.response.data?.title);
    }
  }
);

export const createQuote = createAsyncThunk(
  typePrefix.CREATE,
  async (payload: any) => {
    try {
      const response = await postRequest<any>(
        GET_QUOTES_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.CREATED_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createQuote,
        error.response.data?.title
      );
    }
  }
);

export const editQuote = createAsyncThunk(
  typePrefix.EDIT,
  async (payload: any) => {
    try {
      const response = await putRequest<any>(
        GET_QUOTES_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createQuote,
        error.response.data?.title
      );
    }
  }
);

export const voidQuote = createAsyncThunk(
  typePrefix.VOID,
  async (payload: any) => {
    try {
      const response = await putRequest<any>(
        VOID_QUOTE_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.voidQuote, error.response.data?.title);
    }
  }
);

export const getBuyers = createAsyncThunk(
  GET_BUYERS,
  async (payload: GetBuyersPayload) => {
    try {
      const response = await getRequest<any>(GET_BUYERS, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getBuyers, error.response.data?.title);
    }
  }
);

export const getFinanceStatuses = createAsyncThunk(
  GET_QUOTE_STATUSES_URL,
  async () => {
    try {
      const response = await getRequest<any>(GET_QUOTE_STATUSES_URL);
      const statuses = response.data?.filter((status) => status.isActive);
      return statuses;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getFinanceStatuses,
        error.response.data?.title
      );
    }
  }
);

export const getLoanTypes = createAsyncThunk(GET_LOAN_TYPES_URL, async () => {
  try {
    const response = await getRequest<any>(GET_LOAN_TYPES_URL);
    const loanTypes = response.data?.filter((loanType) => loanType.isActive);
    return loanTypes;
  } catch (error) {
    throw new AppError(ERROR_MESSAGES.getLoanTypes, error.response.data?.title);
  }
});

export const getPaymentTypes = createAsyncThunk(
  GET_PAYMENT_TYPES_URL,
  async () => {
    try {
      const response = await getRequest<any>(GET_PAYMENT_TYPES_URL);
      const paymentTypes = response.data?.filter(
        (paymentType) => paymentType.isActive
      );
      return paymentTypes;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPaymentTypes,
        error.response.data?.title
      );
    }
  }
);

export const takeDeposit = createAsyncThunk(
  typePrefix.TAKE_DEPOSIT,
  async (payload: TakeDeposit) => {
    try {
      const response = await putRequest<any>(DEAL_URL, payload, JSON_HEADER);

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.takeDeposit,
        error.response.data?.title
      );
    }
  }
);
