import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "redux/slices/auth/auth.slice";
import { resetPasswordSlice } from "redux/slices/resetPassword/resetPassword.slice";
import { authenticateCodeSlice } from "redux/slices/authenticateCode/authenticateCode.slice";
import { roleSlice } from "redux/slices/role/role.slice";
import { personnelSlice } from "redux/slices/personnel/personnel.slice";
import { timeClockSlice } from "redux/slices/timeClock/timeClock.slice";
import { assetSlice } from "redux/slices/inventory/inventory.slice";
import { dispatchSlice } from "redux/slices/dispatch/dispatch.slice";
import { purchasingSlice } from "redux/slices/purchasing/purchasing.slice";
import { dealershipSlice } from "redux/slices/dealership/dealership.slice";
import { financeSlice } from "redux/slices/finance/finance.slice";
import { logout } from "redux/slices/auth/auth.action";

const authSliceReducer = authSlice.reducer;
const resetPasswordSliceReducer = resetPasswordSlice.reducer;
const authenticateCodeReducer = authenticateCodeSlice.reducer;
const roleSliceReducer = roleSlice.reducer;
const personnelSliceReducer = personnelSlice.reducer;
const timeClockSliceReducer = timeClockSlice.reducer;
const assetSliceReducer = assetSlice.reducer;
const dispatchSliceReducer = dispatchSlice.reducer;
const purchasingReducer = purchasingSlice.reducer;
const dealershipReducer = dealershipSlice.reducer;
const financeReducer = financeSlice.reducer;

const appReducer = combineReducers({
  auth: authSliceReducer,
  resetPassword: resetPasswordSliceReducer,
  authenticateCode: authenticateCodeReducer,
  role: roleSliceReducer,
  personnel: personnelSliceReducer,
  timeClock: timeClockSliceReducer,
  asset: assetSliceReducer,
  dispatch: dispatchSliceReducer,
  purchasing: purchasingReducer,
  dealership: dealershipReducer,
  finance: financeReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === logout.type) {
    state = undefined; // Reset all state
  }
  return appReducer(state, action);
};

export default rootReducer;
