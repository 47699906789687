import { DISPLAY_MODE, dispatchSuccessMessages } from "appConstants";

export const dispatchFulfilled = (state, action) => {
  state.isLoading = false;
  state.dispatchitems = action.payload?.records;
  state.totalRecords = action.payload?.totalRecords;
};

export const dispatchRejected = (state, action) => {
  state.isLoading = false;
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
};

export const viewDispatchFulfilled = (state, action) => {
  state.isLoading = false;
  state.dispatchitem = action.payload;
};
export const viewDispatchrejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const createDispatchFulfilled = (state, action) => {
  state.isLoading = false;
  state.successMessage = dispatchSuccessMessages.createDispatch;
};

export const createDispatchRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const editDispatchFulfilled = (state, action) => {
  state.isLoading = false;
  state.successMessage = dispatchSuccessMessages.editDispatch;
};

export const editDispatchRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const updateDispatchStatusFulfilled = (state, action) => {
  state.statusLoading = false;
  if (action.meta.arg.mode === DISPLAY_MODE.POPUP) {
    state.statusSuccessMessage =
      dispatchSuccessMessages[action.meta.arg.status];
  } else {
    state.successMessage = dispatchSuccessMessages[action.meta.arg.status];
  }
};

export const updateDispatchStatusRejected = (state, action) => {
  const { name, message } = action.error;
  state.statusLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const addDispatchNoteFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = dispatchSuccessMessages.addNote;
};

export const addDispatchNoteRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};
