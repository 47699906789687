import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  LoginPayload,
  LoginWithRefreshTokenPayload,
  LoginWithGoogleOrMicrosoftPayload,
} from "types/auth";
import {
  LOGIN_URL,
  LOGIN_WITH_GOOGLE_URL,
  LOGIN_WITH_REFRESH_TOKEN_URL,
  LOGIN_WITH_MICROSOFT_URL,
  GET_USER_PERMISSIONS_URL,
  LOGOUT_URL,
} from "utils/urls";
import { getRequest, postRequest } from "utils/axiosClient";
import AppError from "utils/AppError";
import { ERROR_MESSAGES } from "appConstants";

const ssoLogin = async (
  payload: LoginWithGoogleOrMicrosoftPayload,
  loginEndpoint: string
) => {
  try {
    const response = await postRequest(loginEndpoint, payload);
    const { data } = response;
    return data;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.authenticate,
      error.response.data?.message
    );
  }
};

export const login = createAsyncThunk(
  LOGIN_URL,
  async (payload: LoginPayload) => {
    try {
      const response = await postRequest(LOGIN_URL, payload);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.authenticate,
        error.response.data?.message
      );
    }
  }
);

export const loginAndGetRefreshToken = createAsyncThunk(
  LOGIN_WITH_REFRESH_TOKEN_URL,
  async (payload: LoginWithRefreshTokenPayload) => {
    try {
      const response = await postRequest(LOGIN_WITH_REFRESH_TOKEN_URL, payload);
      const { data } = response;
      return data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.authenticate,
        error.response.data?.message
      );
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  LOGIN_WITH_GOOGLE_URL,
  async (payload: LoginWithGoogleOrMicrosoftPayload) => {
    try {
      const token = await ssoLogin(payload, LOGIN_WITH_GOOGLE_URL);
      return token;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.authenticate,
        error.response.data?.message
      );
    }
  }
);

export const loginWithMicrosoft = createAsyncThunk(
  LOGIN_WITH_MICROSOFT_URL,
  async (payload: LoginWithGoogleOrMicrosoftPayload) => {
    try {
      const token = await ssoLogin(payload, LOGIN_WITH_MICROSOFT_URL);
      return token;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.authenticate,
        error.response.data?.message
      );
    }
  }
);

// INFO: To get the current user permission
export const getUserPermissions = createAsyncThunk(
  GET_USER_PERMISSIONS_URL,
  async () => {
    try {
      const response = await getRequest(GET_USER_PERMISSIONS_URL);
      return response?.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getUserPersmissions,
        error.response.data?.message
      );
    }
  }
);

// INFO: This action is created to reset all the state when user click logout
export const logout = createAction(LOGOUT_URL);
