import { ReactNode } from "react";
import { styled } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ExpandableHeadingStyle,
  linkButtonBGGreyA400,
} from "appConstants/styles";
import useExpandAbleHeading from "./useExpandAbleHeading";
import Button from "components/Button";
import AddIcon from "@mui/icons-material/Add";

type ExpandableHeadingPropType = {
  headingTitle?: String;
  headingChildren?: ReactNode;
  noExpandable?: Boolean;
  noMiddleLine?: boolean;
  buttonContainer?: React.ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
} & Partial<AccordionProps>;

const ExpandableHeadingWrapper = styled(Accordion)<ExpandableHeadingPropType>(
  () => ({})
);

const ExpandableHeading = (props: ExpandableHeadingPropType) => {
  const {
    headingTitle = "Heading",
    headingChildren,
    children = "Content not define",
    noExpandable = false,
    noMiddleLine = false,
    buttonContainer,
    buttonText,
    expanded = false,
    onButtonClick = () => {},
    ...otherProps
  } = props;
  const { sx, ...expandableHeadingWrapperProps } = otherProps;

  const { expand, setExpand } = useExpandAbleHeading(expanded);

  return (
    <Box position={"relative"}>
      <ExpandableHeadingWrapper
        sx={{
          ...sx,
          ...ExpandableHeadingStyle,
          ...(noExpandable &&
            ExpandableHeadingStyle["&.MuiAccordion-root"].noExpandable),
        }}
        expanded={expand}
        {...expandableHeadingWrapperProps}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            ...(noMiddleLine
              ? {
                  ".MuiAccordionSummary-content::after": {
                    height: "0 !important",
                  },
                }
              : {}),
          }}
          onClick={() => setExpand(!expand)}
        >
          {headingChildren ?? headingTitle}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </ExpandableHeadingWrapper>

      {(buttonContainer || buttonText) && (
        <Box sx={ExpandableHeadingStyle["&.MuiAccordion-root"].buttonContainer}>
          {buttonText && (
            <Button
              startIcon={<AddIcon />}
              variant="text"
              sx={linkButtonBGGreyA400}
              onClick={onButtonClick}
            >
              {buttonText}
            </Button>
          )}
          {buttonContainer}
        </Box>
      )}
    </Box>
  );
};

export default ExpandableHeading;
