export const QUOTE_FORM_FIELDS = {
  ASSET_ID: "AssetId",
  STOCK_NUMBER: "StockNumber",
  QUOTE_STATUS: "financeStatusId",
  DELIVERY_DATE: "DeliveryDate",
  MSRP: "Selling_Msrp",
  SELLING_PRICE: "Selling_Price",
  REBATE: "Rebate",
  DISCOUNT: "Discount",
  TRADE_ALLOWANCE: "TradeAllowance",
  TRADE_ACV: "TradeACV",
  DEAL_TYPE: "loanTypeId",
  DEPOSIT_AMOUNT: "DepositAmount",
  CASH_ON_DELIVERY: "CashOnDelivery",
  AMOUNT_FINANCED: "AmountFinanced",
  TERM: "Term",
  INTEREST_RATE: "InterestRate",
  TAXES: "Taxes",
  FEES: "Fees",
  MONTHLY_PAYMENT: "MonthlyPayment",
};

export const DEPOSIT_FORM_FIELDS = {
  DEPOSIT_DATE: "depositDate",
  PAYMENT_TYPE: "paymentType",
  DEPOSIT_AMOUNT: "depositAmount",
  PERCENTAGE: "percentage",
  COMMENT: "comment",
};
